import React from 'react';

const DateText = ({ ...props }) => (
  <svg
    width="313"
    height="82"
    viewBox="0 0 313 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: '100%' }}
    {...props}
  >
    <g clipPath="url(#clip0_133_284)">
      <path
        d="M24.21 38.3199L21.91 29.4499L12.55 29.8199L11.12 37.5399L0.00999451 38.1499L9.83999 1.49994L22.35 0.439941L35.51 37.7999L24.21 38.3199ZM16.57 11.6099L13.84 23.0699L20.19 23.0499L16.57 11.6099Z"
        fill="white"
      />
      <path
        d="M64.65 0.95C65.19 4.34 65.5 8.22 65.51 12.2C65.55 25.49 62.05 39.71 50.55 39.75C39.93 39.78 34.89 26.84 34.85 11.76C34.85 8.32 35.26 3.8 35.74 0.75L47 1.93C46.23 4.5 45.9 8.58 45.91 11.49C45.94 20.46 47.6 26.47 51.39 26.46C55.66 26.45 57 20.67 56.98 12.47C56.97 8.44 56.57 4.81 56.03 1.75L64.66 0.95H64.65Z"
        fill="white"
      />
      <path
        d="M87.91 25.1899L87.74 18.1099L105.64 17.2299C105.74 17.9599 105.74 18.6399 105.74 19.3099C105.78 33.3799 96.44 39.4699 87.85 39.4899C78.34 39.5199 68.52 32.1199 68.49 19.7499C68.45 7.76992 77.84 0.609915 88.81 0.579915C92.84 0.569915 97.06 1.47992 101.09 3.49992L96.7 12.4399C94.08 11.1399 90.97 10.1799 88.11 10.1799C83.6 10.1899 79.73 12.5799 79.75 19.7099C79.77 25.8699 84.44 30.2199 88.37 30.2099C90.94 30.2099 93.22 28.7399 94.08 25.2499L87.92 25.1699L87.91 25.1899Z"
        fill="white"
      />
      <path
        d="M138.29 0.740039C138.83 4.13004 139.14 8.01004 139.15 11.99C139.19 25.28 135.69 39.5 124.19 39.54C113.57 39.57 108.53 26.63 108.49 11.55C108.49 8.11004 108.9 3.59004 109.38 0.540039L120.64 1.72004C119.87 4.29004 119.54 8.37004 119.55 11.28C119.58 20.25 121.24 26.26 125.03 26.25C129.3 26.24 130.64 20.46 130.62 12.26C130.61 8.23004 130.21 4.60004 129.67 1.54004L138.3 0.740039H138.29Z"
        fill="white"
      />
      <path
        d="M145.64 25.31C149.14 27.73 152.83 28.78 155.88 28.77C159.62 28.76 162.28 27.15 162.27 24.48C162.25 18.37 142.23 23.13 142.2 11.39C142.18 2.38 150.9 0.02 159.15 0C162.4 0 165.55 0.37 168.08 0.8L167.82 9.68C166.17 8.96 162.43 8.1 159.18 8.1C156.27 8.1 153.8 8.84 153.8 10.98C153.81 15.93 174.56 8.83 174.6 25.13C174.63 34.35 165.91 38.45 156.98 38.47C150.43 38.49 143.83 36.33 140.42 32.36L145.64 25.31Z"
        fill="white"
      />
      <path
        d="M180.71 39.3299L181.36 12.9399L172.05 13.5999V0.639893L201.17 2.29989L200.95 11.5199L190.18 12.2799L191.22 38.0399L180.7 39.3299H180.71Z"
        fill="white"
      />
      <path
        d="M225.33 36.0901L238.46 12.1401L223.52 12.8101L223.73 0.340088L249.35 1.62009L251.03 11.0701L235.15 39.4001L225.34 36.0801L225.33 36.0901Z"
        fill="white"
      />
      <path
        d="M260.94 39.0899L261.59 12.6999L252.28 13.3599V0.409912L281.4 2.06991L281.18 11.2899L270.41 12.0499L271.45 37.7999L260.93 39.0899H260.94Z"
        fill="white"
      />
      <path
        d="M301.59 37.0401L301.41 24.2801L295.4 24.8801L295.1 38.1201L285.3 37.3701L284.62 2.49011L295.58 1.88011L295.28 16.8701L301.34 16.7101L301.15 2.21011L310.75 1.36011L312.17 37.2501L301.59 37.0401Z"
        fill="white"
      />
      <path
        d="M42.46 80.98L40.83 65.91L37.02 67.44L36.72 61.23L41.11 53.98L50.47 55.44L49.66 81.17L42.46 80.98Z"
        fill="white"
      />
      <path
        d="M53.32 66.6201C53.3 59.3101 57.55 53.27 64.26 53.25C71.04 53.23 75.11 59.5 75.14 67.27C75.16 73.87 72.11 81.11 64.13 81.14C57.46 81.16 53.35 74.71 53.32 66.63V66.6201ZM68.6 66.65C68.6 63.54 67.03 62 65.09 62C62.05 62 60.3 64.4501 60.31 68.0501C60.31 70.4901 61.17 73.41 64.21 73.4C67.49 73.4 68.61 69.6101 68.6 66.65Z"
        fill="white"
      />
      <path
        d="M91.27 71.8801L90.76 66.2701L102.23 64.6101L102.81 71.2801L91.27 71.8801Z"
        fill="white"
      />
      <path
        d="M124.51 80.74L122.88 65.68L119.07 67.21L118.77 61L123.16 53.75L132.52 55.2L131.71 80.93L124.51 80.74Z"
        fill="white"
      />
      <path
        d="M140.49 80.6999L138.86 65.6299L135.05 67.16L134.75 60.95L139.14 53.7L148.5 55.16L147.69 80.8899L140.49 80.6999Z"
        fill="white"
      />
      <path
        d="M181.89 80.09L180.21 73.64L173.4 73.91L172.36 79.52L164.28 79.97L171.44 53.3L180.54 52.53L190.11 79.71L181.89 80.09ZM176.33 60.66L174.34 68.99H178.96L176.32 60.66H176.33Z"
        fill="white"
      />
      <path
        d="M212.84 79.85L211.56 65.49L207.08 77.04L203.98 77.58L200.31 66.26L197.67 80.39L190.36 78.68L195.9 52.9L201.44 52.71L205.96 65.75L211.29 52.75L217.15 53.33L221.53 79.44L212.85 79.85H212.84Z"
        fill="white"
      />
      <path
        d="M239.02 79.4999L236.93 53.6699L253.55 51.6499L254.03 57.7499L244.33 58.9099L244.69 62.5099L252.28 61.9199L252.86 67.5299L245.41 68.1899L245.95 71.9999L255.48 71.0199L256 79.6999L239.02 79.4999Z"
        fill="white"
      />
      <path
        d="M262.42 80.91L262.89 61.71L256.11 62.19V52.77L277.3 53.98L277.14 60.69L269.31 61.24L270.07 79.98L262.41 80.92L262.42 80.91Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_284">
        <rect width="312.16" height="81.17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DateText;
