import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import EventButton from '../shared/EventButton';
import CandleOffImage from './images/tisha-bav-2023-candle-off.svg';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '36px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125.188%',
    marginBottom: '6px',
    marginTop: '72px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '292px',
      marginTop: '82px',
      marginBottom: '40px'
    }
  },
  paragraph: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '26px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '127.188%',
    maxWidth: '653px',
    marginBottom: '67px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '90px'
    }
  },
  buttonEnded: {
    margin: '30px 0'
  },
  leftCandle: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
      bottom: '65px'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      left: 'unset',
      bottom: 'unset'
    }
  },
  rightCandle: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    transform: 'scaleX(-1)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
      bottom: '65px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const PostEvent = ({ playlistUrl }) => {
  const classes = useStyles();

  return (
    <>
      <Typography component="p" className={classes.title}>
        You missed the event,
        <br />
        but don’t worry…
      </Typography>
      <Typography component="p" className={classes.paragraph}>
        If you’re looking to do something meaningful this Tisha B’Av, we’ve got you covered. Our
        library of incredible videos will transform your Tisha B’Av.
      </Typography>
      <EventButton to={playlistUrl} variant="orange" text="Start Watching" />
      <img src={CandleOffImage} alt="Left Candle" className={classes.leftCandle} />
      <img src={CandleOffImage} alt="Right Candle" className={classes.rightCandle} />
    </>
  );
};

export default PostEvent;

PostEvent.propTypes = {
  playlistUrl: PropTypes.string.isRequired
};
