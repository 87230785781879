import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import HostCard from './HostCard';
import EventButton from '../shared/EventButton/EventButton';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: '#F6F6F6'
  },
  root: {
    paddingTop: '96px',
    paddingBottom: '32px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '48px'
    }
  },
  heading: {
    fontSize: '52px',
    fontWeight: 500,
    marginBottom: '40px',
    fontFamily: 'Roboto',
    color: '#1A1B34',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      width: '100%',
      textAlign: 'center'
    }
  },
  hosts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '100px',
    [theme.breakpoints.down('xs')]: {
      gap: '64px'
    }
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: '72px'
  },
  shapeDivider: {
    display: 'flex',
    marginTop: '-5px'
  }
}));

const TishaBavHosts = ({ hosts, ctaText, ctaLink }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <div className={classes.section}>
        <Container size={100} className={classes.root}>
          <Typography component="h3" className={classes.heading}>
            Your Hosts
          </Typography>
          <div className={classes.hosts}>
            {hosts.map(host => (
              <HostCard
                key={host.name}
                image={host.image}
                name={host.name}
                title={host.title}
                description={host.description}
                variant={isMediumScreen ? 'column' : 'row'}
                centerDescription={isMediumScreen}
              />
            ))}
          </div>

          {!isExtraSmallScreen && (
            <div className={classes.buttonArea}>
              <EventButton to={ctaLink} variant="blue" text={ctaText} />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

TishaBavHosts.propTypes = {
  hosts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      })
    })
  ).isRequired,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string
};

TishaBavHosts.defaultProps = {
  ctaText: 'Start your free trial today to gain access to this event',
  ctaLink: '/subscribe'
};

export default TishaBavHosts;
