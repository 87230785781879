import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Link from '~src/components/Link';

const getButtonVariantStyles = variant => {
  switch (variant) {
    case 'orange':
      return {
        backgroundColor: '#FF4B33'
      };
    case 'blue':
    default:
      return {
        backgroundColor: '#0094FF'
      };
  }
};
const useStyles = makeStyles(theme => ({
  button: props => ({
    fontSize: '16px',
    lineHeight: '140%',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginBottom: '30px',
    textTransform: 'none',
    padding: '20px 48px',
    boxShadow: 'unset',
    borderRadius: '40px',
    minWidth: '185px',
    ...getButtonVariantStyles(props.variant),
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      padding: '22px 16px'
    }
  })
}));

const EventButton = ({ text, to, variant }) => {
  const classes = useStyles({ variant });

  return (
    <Link to={to} target="_blank" underline="none">
      <Button variant="contained" color="primary" size="large" className={classes.button}>
        {text}
      </Button>
    </Link>
  );
};

EventButton.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['blue', 'orange'])
};

EventButton.defaultProps = {
  variant: 'blue'
};

export default EventButton;
