import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  categoryButton: {
    fontSize: 12,
    color: '#FFFFFF',
    padding: '8px 16px 8px 16px',
    backgroundColor: '#F8A39B',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FF8D82'
    },
    '&:active': {
      backgroundColor: '#FF796C'
    }
  },
  categoryButtonActive: {
    fontWeight: 700,
    backgroundColor: '#FF796C'
  }
});

const CategoryButtons = ({ carousels, onChangeCategory, selectedIndex }) => {
  const classes = useStyles();

  return (
    <Box
      component="div"
      display="flex"
      gridGap={8}
      marginLeft="15px"
      flexWrap="wrap"
      sx={{ marginBottom: { xs: '15px', sm: '39px' } }}
    >
      {carousels.map((item, index) => {
        const active = selectedIndex === index;
        return (
          <Button
            key={item.category}
            variant="text"
            className={`${classes.categoryButton} ${active && classes.categoryButtonActive}`}
            onClick={() => onChangeCategory(index)}
            disableRipple
          >
            {item.category}
          </Button>
        );
      })}
    </Box>
  );
};

CategoryButtons.propTypes = {
  onChangeCategory: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          label: PropTypes.string,
          link: PropTypes.string,
          image: PropTypes.string,
          duration: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default CategoryButtons;
