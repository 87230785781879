import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useHeaderStyle from '~styles/useHeaderStyle';
import TestimonialCarousel from '~components/TestimonialCarousel';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '90px',
    gap: 60,
    [theme.breakpoints.down('xs')]: {
      gap: 15,
      paddingTop: '45px',
      paddingBottom: '75px'
    }
  },
  section: {
    position: 'relative',
    backgroundColor: '#FFFFFF'
  },
  shapeDivider: {
    display: 'flex',
    marginTop: '-5px'
  }
}));

const TishaBavTestimonials = ({ title, testimonials, bottomShapeDividerSrc }) => {
  const classes = useStyles();
  const headerStyle = useHeaderStyle();

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <Typography variant="h3" className={headerStyle.h2}>
            {title}
          </Typography>
          <Box component="div" width="100%">
            <TestimonialCarousel testimonials={testimonials} />
          </Box>
        </div>
      </div>
      {bottomShapeDividerSrc && (
        <BottomShapeDivider
          src={bottomShapeDividerSrc}
          bgColor="transparent"
          className={classes.shapeDivider}
        />
      )}
    </>
  );
};

TishaBavTestimonials.propTypes = {
  title: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      testimonial: PropTypes.string,
      author: PropTypes.string,
      designation: PropTypes.string
    })
  ),
  bottomShapeDividerSrc: PropTypes.string
};

TishaBavTestimonials.defaultProps = {
  title: 'AlephBeta Stories',
  testimonials: [],
  bottomShapeDividerSrc: ''
};

export default TishaBavTestimonials;
