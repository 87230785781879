import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import unfurl from '~prismic/unfurl/tisha-bav-event';
import {
  PostTishaBavHero,
  TishaBavHosts,
  TishaBavTestimonials,
  TishaBavTopVideos
} from '~src/modules/tisha-bav-event';
import Page from '~src/layout/Page';
import SliceToComponent from '~components/slices/slices';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import BottomShapeDivider01Src from '~images/shape-dividers/tb-bottom-shape-divider-01.svg';
import BottomShapeDivider02Src from '~images/shape-dividers/tb-bottom-shape-divider-02.svg';
import { getNavigationPageLink } from '~utils/common-site-helper';

const TishaBavEvents = ({ data }) => {
  const {
    currentEventPeriod,
    eventLaunchTime,
    eventEndTime,
    subscribeCtaText,
    categoryCarousels,
    sectionTitles,
    testimonials,
    hosts,
    postEventPlaylistUrl,
    preEventSlices,
    seo
  } = unfurl(data);
  const [isEventLive, setIsEventLive] = useState(null);
  const [isEventEnded, setIsEventEnded] = useState(null);

  const eventLaunch = Date.parse(eventLaunchTime);
  const eventEnd = Date.parse(eventEndTime);

  const { isPreEvent, isLiveEvent, isPostEvent } = useMemo(
    () => ({
      isPreEvent: !isEventEnded && !isEventLive,
      isLiveEvent: isEventLive,
      isPostEvent: isEventEnded
    }),
    [isEventEnded, isEventLive]
  );

  const isLoading = isEventEnded === null && isEventLive === null;

  console.log(
    currentEventPeriod,
    `Event starts at: ${eventLaunch}`,
    eventLaunchTime,
    `Event ends at: ${eventEnd}`,
    eventEndTime
  );

  useEffect(() => {
    let updateInterval = null;
    if (currentEventPeriod === 'Pre-Event') {
      setIsEventLive(false);
      setIsEventEnded(false);
    } else if (currentEventPeriod === 'Live-Event') {
      setIsEventLive(true);
      setIsEventEnded(false);
    } else if (currentEventPeriod === 'Post-Event') {
      setIsEventLive(false);
      setIsEventEnded(true);
    } else {
      updateInterval = setInterval(() => {
        const dateNow = Date.now();

        if (dateNow < eventLaunch) {
          setIsEventLive(false);
          setIsEventEnded(false);
        }

        if (dateNow > eventLaunch && dateNow < eventEnd) {
          setIsEventLive(true);
        }

        if (dateNow > eventEnd) {
          setIsEventLive(false);
          setIsEventEnded(true);
        }
      }, 1000);
    }

    return () => updateInterval && clearInterval(updateInterval);
  }, [eventLaunch, eventEnd, currentEventPeriod]);

  const slices = useMemo(() => {
    if (isPreEvent || isLiveEvent) {
      return preEventSlices;
    }
    return [];
  }, [isPreEvent, isLiveEvent, isPostEvent, preEventSlices]);

  const globalProps = useMemo(
    () => ({
      cta_path: getNavigationPageLink('/subscribe', true),
      cta_text: subscribeCtaText,
      is_event_live: isLiveEvent
    }),
    [subscribeCtaText, isLiveEvent]
  );

  return (
    <Page seo={seo} hideMarketingSecondaryHeader redirectLoggedInUsersToMembers>
      <SliceToComponent slices={slices} globalProps={globalProps} />
      {isPostEvent && (
        <PostTishaBavHero
          isLoading={isLoading}
          postEventPlaylistUrl={postEventPlaylistUrl}
          bottomShapeDividerSrc={!isPostEvent ? BottomShapeDivider01Src : ''}
        />
      )}

      {isPostEvent ? (
        <TishaBavTopVideos
          title={sectionTitles?.categoryCarousels}
          carousels={categoryCarousels}
          variant="dark"
          bottomShapeDividerSrc={BottomShapeDivider02Src}
        />
      ) : (
        <TishaBavHosts
          hosts={hosts}
          isLoading={isLoading}
          isPreEvent={isPreEvent}
          isLiveEvent={isLiveEvent}
          ctaText={globalProps.cta_text}
          ctaLink={globalProps.cta_path}
        />
      )}
      <TishaBavTestimonials title={sectionTitles.testimonials} testimonials={testimonials} />
      {!isPostEvent && (
        <TishaBavTopVideos title={sectionTitles?.categoryCarousels} carousels={categoryCarousels} />
      )}
    </Page>
  );
};

TishaBavEvents.propTypes = {
  data: PropTypes.shape().isRequired
};

TishaBavEvents.defaultProps = {};

export default withPrismicPreview(TishaBavEvents);

export const query = graphql`
  {
    prismicTishaBavEvent {
      data {
        seo_title
        seo_description
        seo_keywords
        seo_image {
          url
        }
        current_event_period
        event_launch
        event_end
        subscribe_cta_text
        post_event_playlist_url
        hosts {
          host_name
          host_title
          host_description {
            html
          }
          host_avatar {
            url
            alt
          }
        }
        testimonial_section_title
        testimonials {
          author
          designation
          testimonial {
            html
          }
        }
        category_carousels_section_title
        body1 {
          ... on PrismicTishaBavEventDataBody1TbEventHero {
            id
            slice_type
            primary {
              hero_title {
                text
                html
              }
              hero_description {
                text
                html
              }
              background_color
              background_image {
                alt
                url
              }
              background_color_mobile
              background_image_mobile {
                alt
                url
              }
              live_event_label
              live_event_label_color
              cta_theme
              text_color
              text_under_cta {
                html
                text
              }
              top_label_background_color
              top_label_text
              top_label_text_color
            }
            items {
              disabled
              heading_text
              heading_text_color
              list_item {
                html
                text
              }
              list_item_color
              list_item_icon
            }
          }
          ... on PrismicTishaBavEventDataBody1TbDescription {
            slice_type
            id
            primary {
              content {
                html
                text
              }
              cta_theme
              text_color
              text_under_cta {
                html
                text
              }
            }
            items {
              disabled
              heading_text
              heading_text_color
              list_item {
                html
                text
              }
              list_item_color
              list_item_icon
            }
          }
        }
        body {
          ... on PrismicTishaBavEventDataBodyCategoryCarousel {
            __typename
            primary {
              category
            }
            items {
              label
              duration
              card_image {
                url
              }
              resource {
                id
                url
                document {
                  __typename
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPodcastPlaylist {
                    data {
                      podcast_title {
                        text
                      }
                      hosts {
                        ... on PrismicPodcastPlaylistDataHostsHost {
                          items {
                            host {
                              document {
                                ... on PrismicAuthor {
                                  data {
                                    name
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      podcast_thumbnail {
                        url
                      }
                      cover_color
                      episodes {
                        ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                          items {
                            podcast_episode {
                              document {
                                ... on PrismicPodcastEpisode {
                                  data {
                                    length_in_seconds
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPodcastEpisode {
                    data {
                      title
                      length_in_seconds
                      cover_image {
                        alt
                        url
                      }
                      podcast_playlist {
                        url
                      }
                    }
                  }
                  ... on PrismicMaterial {
                    data {
                      title
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
