import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '~components/Container';
import useHeaderStyle from '~styles/useHeaderStyle';
import CategoryCarousel from '~components/CategoryCarousel/CategoryCarousel';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '90px',
    paddingBottom: '90px',
    gap: 30,
    marginTop: '-2px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '165px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
      paddingBottom: '60px',
      gap: 25
    }
  },
  title: props => ({
    marginBottom: 30,
    color: props.textColor,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  }),
  section: props => ({
    position: 'relative',
    backgroundColor: props.backgroundColor
  }),
  shapeDivider: {
    display: 'flex',
    marginTop: '-5px'
  }
}));

const TishaBavTopVideos = ({ title, carousels, variant, bottomShapeDividerSrc }) => {
  const classes = useStyles({
    backgroundColor: variant === 'light' ? '#F6F6F6' : '#000014',
    textColor: variant === 'light' ? '#000000' : '#FFFFFF'
  });
  const headerStyle = useHeaderStyle();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Typography variant="h3" className={`${headerStyle.h2} ${classes.title}`}>
          {title}
        </Typography>
        <Container>
          <CategoryCarousel carousels={carousels} variant={variant} />
        </Container>
      </div>
      {bottomShapeDividerSrc && (
        <BottomShapeDivider
          src={bottomShapeDividerSrc}
          bgColor="transparent"
          className={classes.shapeDivider}
        />
      )}
    </div>
  );
};

TishaBavTopVideos.propTypes = {
  title: PropTypes.string,
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          label: PropTypes.string,
          link: PropTypes.string,
          image: PropTypes.string,
          duration: PropTypes.string
        })
      )
    })
  ),
  variant: PropTypes.oneOf(['light', 'dark']),
  bottomShapeDividerSrc: PropTypes.string
};

TishaBavTopVideos.defaultProps = {
  variant: 'light',
  title: 'Top Categories',
  carousels: [],
  bottomShapeDividerSrc: ''
};

export default TishaBavTopVideos;
