import React from 'react';

const DiagonalText = () => (
  <svg
    width="534"
    height="44"
    viewBox="0 0 534 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: '100%' }}
  >
    <path
      d="M5.63941 41.3258C4.5766 39.4108 1.09533 29.4398 0.238574 26.4683C0.119279 26.0501 0.010828 25.6759 -1.70443e-05 25.3898C-0.0217072 24.7295 0.412095 24.2892 1.17125 24.2672C2.47266 24.2232 3.27519 25.4888 3.70899 27.1066L6.49617 37.6169L10.8342 27.1837C11.3114 25.9841 11.6693 25.5549 12.2874 25.5329C12.9381 25.5109 13.5021 25.9511 14.0769 27.7009L17.1026 37.2647C19.5102 33.0716 20.1935 29.869 20.7032 27.5909C20.9743 26.3583 21.2238 25.6869 22.0155 25.6649C22.5686 25.6429 22.9156 25.9841 22.9481 26.9196C23.0349 29.6379 20.3453 37.716 18.9463 40.2032C18.6535 40.7315 18.2197 41.2707 17.4714 41.3037C16.7122 41.3258 16.2893 40.8525 15.9639 40.2692C15.1614 38.8715 12.9165 33.3578 11.9187 29.9461C11.0294 33.1817 8.99054 40.1592 8.50251 41.2597C8.27476 41.755 7.91688 42.3273 7.12519 42.3493C6.47448 42.3713 6.00815 41.9641 5.65026 41.3148L5.63941 41.3258Z"
      fill="white"
    />
    <path
      d="M24.3797 20.4262C24.3363 19.1055 25.2798 18.6213 26.0064 18.5883C27.2753 18.5442 28.414 19.8319 28.4683 21.2626C28.5116 22.4842 27.7091 23.3096 26.8198 23.3426C25.5835 23.3867 24.4339 21.9559 24.3905 20.4262H24.3797ZM26.6788 40.3681C26.3752 38.3872 25.3124 29.99 25.2798 29.0105C25.2256 27.3046 25.3449 25.8629 26.6246 25.8299C27.6549 25.7969 28.6418 26.6993 28.696 28.064C28.7719 30.3972 28.8804 37.0225 28.9563 39.0805C29.0105 40.8524 28.696 41.4907 27.8718 41.5237C27.1126 41.5457 26.8307 41.2046 26.7005 40.3792L26.6788 40.3681Z"
      fill="white"
    />
    <path
      d="M31.2446 27.5908C31.2121 26.6884 31.6893 26.3252 32.7846 26.2151C33.1967 26.2041 33.6739 26.1491 34.1945 26.0941C34.086 23.97 34.0318 22.1541 33.9993 21.0095C33.9559 19.8209 34.1728 19.0836 35.3766 19.0395C36.4069 19.0065 37.3721 20.1291 37.4155 21.4497C37.4697 22.9795 37.5131 24.4432 37.5239 25.8409C39.7146 25.6648 41.8077 25.5218 42.3934 25.4998C44.66 25.4227 45.2564 25.6869 45.2781 26.5233C45.2998 27.2166 44.5515 27.5578 44.1502 27.7119C43.0657 28.1301 40.1701 28.9225 37.5348 29.3957C37.5022 33.7539 37.3721 37.1436 37.2419 39.4878C37.1986 40.3242 37.025 41.2046 36.331 41.2266C35.7453 41.2486 35.3549 40.6653 35.2139 39.5868C34.9319 37.3637 34.6391 33.5008 34.4114 29.7809C32.969 29.8249 31.2989 29.3297 31.2446 27.5798V27.5908Z"
      fill="white"
    />
    <path
      d="M48.2822 39.0474C47.5773 33.2475 46.4494 18.8413 46.4169 17.7298C46.3735 16.3651 46.7639 15.7268 47.6207 15.7048C48.7919 15.6608 49.909 17.2015 49.9523 18.6983C50.0608 22.187 49.8114 29.0655 49.627 32.0369C50.744 29.4507 52.7395 26.2811 53.8674 25.0264C54.3663 24.4542 54.8977 24.0469 55.5159 24.0249C56.6437 23.9919 57.3053 25.2576 57.8801 26.8423C59.0514 30.078 60.2226 35.4156 60.3311 38.6182C60.3745 40.082 60.1792 40.4341 59.3984 40.4672C58.6392 40.4892 58.2597 40.192 58.1295 39.5317C57.2945 35.1295 56.9257 33.2255 55.223 29.5167C54.9628 28.9334 54.7784 28.6252 54.4422 28.6362C54.1277 28.6362 53.965 28.9334 53.7156 29.4617C51.8719 33.1485 51.4707 35.3936 50.6139 39.7518C50.4621 40.4892 50.0283 40.7863 49.5185 40.7973C48.6943 40.8303 48.4232 40.0269 48.293 39.0585L48.2822 39.0474Z"
      fill="white"
    />
    <path
      d="M72.6727 36.5933C72.2823 36.1861 72.0003 35.9219 71.9787 35.3606C71.957 34.7663 72.2498 34.2051 73.0415 34.172C73.5186 34.161 73.8765 34.3591 74.2236 34.5903C76.436 35.9439 78.7568 36.6373 81.4355 36.5492C85.5892 36.4062 89.5802 34.4582 89.4717 31.1565C89.3958 28.6803 86.8364 28.2071 84.3962 28.2951C83.1924 28.3391 80.5246 28.5702 79.3858 28.6033C74.9177 28.7573 72.3799 26.6113 72.2606 23.0565C72.0871 17.7959 77.4554 14.7583 83.1491 14.5602C85.7627 14.4722 87.422 15.0115 87.4979 17.1025C87.5305 18.082 87.2051 18.6873 86.6954 18.6983C86.1423 18.7203 84.1685 18.2911 82.2489 18.3571C78.746 18.4782 74.8526 20.2831 74.9285 22.6823C74.9827 24.4982 76.9131 24.6083 78.7677 24.5422L84.1251 24.3661C88.6909 24.2121 91.9444 27.1395 92.0854 31.3216C92.2589 36.4832 87.5522 39.9499 82.1296 40.137C78.041 40.2691 74.614 38.5412 72.6619 36.6153L72.6727 36.5933Z"
      fill="white"
    />
    <path
      d="M94.3412 35.0964C94.2219 31.3656 98.6575 28.4601 102.952 28.317C104.221 28.273 106.054 28.4601 107.659 29.3075L107.637 28.7793C107.583 26.9634 105.696 25.1144 102.573 25.2135C100.957 25.2685 99.1889 25.8518 97.573 26.9854C96.7705 27.5687 96.2607 28.0089 95.4799 28.0309C94.6231 28.0639 93.7447 27.5687 93.7122 26.6222C93.6905 26.0279 94.0267 25.5657 94.8834 24.7733C96.8247 23.0014 98.9829 21.8128 101.933 21.7137C106.943 21.5487 110.077 24.6852 110.218 29.2085L110.479 37.3305C110.5 38.167 110.218 38.7393 109.394 38.7613C108.711 38.7833 108.114 38.3871 108.082 37.5507C108.071 37.1324 108.169 36.1199 108.299 35.7678C106.726 38.0569 103.776 39.3776 100.751 39.4766C97.0416 39.5977 94.4062 37.8808 94.3195 35.0854L94.3412 35.0964ZM107.854 33.0054C107.8 31.2995 105.772 30.4301 103.755 30.4961C101.076 30.5842 97.8333 32.3671 97.9092 34.5682C97.9634 36.175 99.7854 37.1214 101.911 37.0444C104.655 36.9564 107.93 35.2065 107.865 33.0054H107.854Z"
      fill="white"
    />
    <path
      d="M114.806 36.8135C113.797 31.0246 112.854 25.5439 112.821 24.4323C112.799 23.6289 113.19 22.9906 113.982 22.9686C115.218 22.9246 116.129 24.4653 116.172 25.9621C116.216 27.3928 116.237 29.0326 116.172 30.2872C117.647 25.0376 120.847 20.9216 124.425 20.8005C125.488 20.7675 126.247 21.9231 126.28 22.9686C126.312 24.0801 125.532 24.3223 124.274 24.7075C118.797 26.3583 117.626 31.6299 117.029 37.5069C116.953 38.2442 116.508 38.5304 115.999 38.5524C115.175 38.5854 114.979 37.782 114.806 36.8135V36.8135Z"
      fill="white"
    />
    <path
      d="M127.668 16.9594C127.625 15.6387 128.568 15.1545 129.295 15.1215C130.564 15.0774 131.713 16.3651 131.757 17.7958C131.8 19.0174 130.998 19.8428 130.108 19.8758C128.872 19.9199 127.722 18.4891 127.679 16.9594H127.668ZM129.967 36.9013C129.664 34.9204 128.601 26.5232 128.568 25.5437C128.514 23.8378 128.633 22.3961 129.913 22.3631C130.943 22.3301 131.93 23.2325 131.984 24.5972C132.06 26.9304 132.169 33.5557 132.245 35.6137C132.299 37.3856 131.984 38.0239 131.16 38.0569C130.401 38.0789 130.119 37.7378 129.989 36.9124L129.967 36.9013Z"
      fill="white"
    />
    <path
      d="M145.985 35.8339C145.617 33.0935 144.76 22.5173 144.532 15.5838C144.489 14.1861 144.847 13.5478 145.801 13.5147C147.07 13.4707 148.122 15.0115 148.165 16.5082C148.23 18.5333 148.252 21.2846 148.241 24.08C153.675 17.6858 154.867 16.3212 158.143 13.5588C159.78 12.1831 160.561 11.7319 161.353 11.7098C162.242 11.6768 163.121 12.2491 163.164 13.3607C163.196 14.3732 162.654 14.5933 161.852 15.1876C158.858 17.3447 155.236 19.8429 151.657 23.9039C156.299 29.2196 160.941 34.6453 163.131 37.3637C163.489 37.8039 163.706 37.969 163.717 38.3542C163.739 38.9155 163.403 39.3447 162.817 39.3667C162.34 39.3777 162.047 39.0805 161.667 38.7724C158.251 36.1311 153.338 31.1346 148.295 25.973L148.241 35.8119C148.209 36.8904 147.883 37.4957 147.167 37.5178C146.376 37.5398 146.148 36.8574 146.018 35.8449L145.985 35.8339Z"
      fill="white"
    />
    <path
      d="M162.795 29.869C162.622 24.4323 167.741 19.0946 173.51 18.9075C177.978 18.7535 181.546 21.747 181.687 26.1712C181.861 31.6079 176.742 36.9456 170.972 37.1327C166.504 37.2867 162.936 34.2932 162.795 29.869ZM178.748 26.8865C178.651 24.0251 176.46 22.0111 173.467 22.1101C169.584 22.2422 165.929 25.885 166.049 29.7259C166.146 32.5874 168.337 34.6014 171.33 34.5023C175.18 34.3703 178.868 30.7274 178.748 26.8975V26.8865Z"
      fill="white"
    />
    <path
      d="M185.505 42.3271C185.082 38.7173 184.334 29.6707 184.062 21.3065C184.019 19.9088 184.409 19.2705 185.299 19.2375C186.427 19.2045 187.283 20.184 187.327 21.3616C187.348 22.165 187.283 23.1775 187.153 24.3C188.726 20.9324 192.25 18.4121 196.165 18.28C200.905 18.126 204.267 21.1525 204.419 25.8188C204.603 31.3986 200.189 36.142 194.832 36.3291C191.567 36.4391 188.943 34.9534 187.522 32.5982C187.544 35.5257 187.544 41.6337 187.522 42.294C187.457 43.3726 187.229 43.9779 186.513 43.9999C185.83 44.0219 185.592 43.1635 185.494 42.3271H185.505ZM201.49 26.5452C201.393 23.6177 199.159 21.5267 196.035 21.6367C192.185 21.7688 188.661 25.1255 188.78 28.9994C188.878 31.8608 191.242 33.8638 194.365 33.7648C198.172 33.6327 201.61 30.3421 201.48 26.5452H201.49Z"
      fill="white"
    />
    <path
      d="M206.447 14.307C206.403 12.9864 207.347 12.5021 208.073 12.4691C209.342 12.4251 210.481 13.7127 210.535 15.1435C210.579 16.3651 209.776 17.1905 208.887 17.2235C207.65 17.2675 206.501 15.8368 206.457 14.307H206.447ZM208.746 34.249C208.442 32.268 207.379 23.8708 207.347 22.8913C207.292 21.1855 207.412 19.7438 208.691 19.7107C209.722 19.6777 210.709 20.5802 210.763 21.9449C210.839 24.278 210.947 30.9033 211.023 32.9614C211.077 34.7332 210.763 35.3826 209.939 35.4046C209.18 35.4266 208.898 35.0964 208.767 34.26L208.746 34.249Z"
      fill="white"
    />
    <path
      d="M213.312 21.4717C213.279 20.5692 213.756 20.206 214.852 20.096C215.264 20.085 215.741 20.0299 216.251 19.9749C216.142 17.8509 216.088 16.035 216.055 14.8904C216.012 13.7018 216.229 12.9644 217.433 12.9204C218.463 12.8874 219.428 13.9989 219.472 15.3306C219.526 16.8604 219.569 18.3241 219.58 19.7218C221.771 19.5457 223.864 19.4026 224.449 19.3806C226.716 19.3036 227.312 19.5677 227.334 20.4041C227.356 21.0975 226.608 21.4387 226.206 21.5927C225.122 22.0109 222.226 22.8033 219.591 23.2766C219.558 27.6348 219.428 31.0244 219.298 33.3686C219.255 34.205 219.081 35.0855 218.387 35.1075C217.801 35.1295 217.411 34.5462 217.27 33.4677C216.988 31.2446 216.695 27.3816 216.467 23.6618C215.025 23.7058 213.355 23.2105 213.301 21.4607L213.312 21.4717Z"
      fill="white"
    />
    <path
      d="M231.043 32.9065C229.991 27.1176 228.961 21.6368 228.918 20.5253C228.896 19.7219 229.286 19.0836 230.078 19.0505C231.314 19.0065 232.225 20.5473 232.269 22.044C232.323 23.6068 232.334 25.0816 232.269 26.1271C233.321 23.5078 235.446 20.1621 236.607 18.8634C237.106 18.2912 237.637 17.884 238.255 17.8619C239.383 17.8179 240.045 19.0946 240.619 20.6794C241.791 23.915 242.962 29.2526 243.07 32.4552C243.114 33.919 242.919 34.2712 242.138 34.3042C241.379 34.3262 240.999 34.029 240.869 33.3687C240.034 28.9665 239.665 27.0625 237.962 23.3537C237.702 22.7704 237.518 22.4622 237.181 22.4733C236.867 22.4733 236.704 22.7704 236.455 23.2987C234.622 26.9855 234.21 29.2306 233.353 33.5888C233.201 34.3262 232.768 34.6233 232.258 34.6343C231.434 34.6673 231.238 33.8639 231.065 32.8955L231.043 32.9065Z"
      fill="white"
    />
    <path
      d="M244.968 13.0084C244.925 11.6878 245.868 11.2035 246.595 11.1705C247.864 11.1265 249.003 12.4142 249.057 13.8449C249.1 15.0665 248.298 15.8919 247.408 15.9249C246.172 15.9689 245.022 14.5382 244.979 13.0084H244.968ZM247.267 32.9504C246.964 30.9694 245.901 22.5722 245.868 21.5927C245.814 19.8869 245.933 18.4452 247.213 18.4122C248.243 18.3791 249.23 19.2816 249.285 20.6463C249.36 22.9794 249.469 29.6047 249.545 31.6628C249.599 33.4347 249.285 34.073 248.46 34.106C247.701 34.128 247.419 33.7868 247.289 32.9614L247.267 32.9504Z"
      fill="white"
    />
    <path
      d="M254.599 32.1912C254.23 29.4508 253.341 18.9846 253.08 10.8295C253.037 9.43178 253.427 8.79346 254.317 8.76044C255.445 8.71642 256.562 10.2572 256.616 11.7539C256.692 14.1972 256.703 17.6419 256.713 21.0316C260 18.6544 263.741 16.1561 265.802 16.0901C267.548 16.0351 267.916 17.7629 267.938 18.2912C267.981 19.6119 265.964 19.931 264.229 20.3382C262.657 20.7014 261.16 21.2407 259.642 21.857C261.605 24.1241 268.719 32.4003 269.044 32.8075C269.229 33.0496 269.337 33.3247 269.348 33.6329C269.37 34.1942 269.066 34.5794 268.448 34.6014C268.101 34.6014 267.83 34.5133 267.645 34.3482C263.958 30.8485 259.728 26.5894 256.768 23.4088C256.768 27.7669 256.724 31.5749 256.713 32.1691C256.681 33.2807 256.356 33.853 255.705 33.875C254.978 33.897 254.751 33.2477 254.62 32.2022L254.599 32.1912Z"
      fill="white"
    />
    <path
      d="M269.283 26.2921C269.109 20.8554 274.228 15.5177 279.998 15.3307C284.466 15.1766 288.034 18.1591 288.175 22.5943C288.348 28.031 283.23 33.3687 277.46 33.5558C272.992 33.7098 269.424 30.7163 269.283 26.2921V26.2921ZM285.236 23.3096C285.138 20.4482 282.948 18.4342 279.954 18.5333C276.072 18.6653 272.417 22.3081 272.536 26.1491C272.634 29.0105 274.825 31.0245 277.818 30.9255C281.668 30.7934 285.355 27.1506 285.236 23.3206V23.3096Z"
      fill="white"
    />
    <path
      d="M289.661 18.9076C289.628 17.8621 290.203 17.5979 291.201 17.5319C291.515 17.5319 291.992 17.5099 292.611 17.4438C292.535 16.2993 292.47 15.2207 292.448 14.5164C292.285 9.60792 293.684 7.1537 297.74 7.01063C302.306 6.85655 304.811 9.83904 304.865 11.6219C304.887 12.2823 304.562 12.7445 303.976 12.7665C303.738 12.7665 303.25 12.7225 302.87 12.4143C301.352 11.2808 299.454 9.88306 297.567 9.93809C295.81 9.99312 295.517 11.2257 295.593 13.845L295.701 17.1907C298.033 17.0036 300.462 16.8165 301.254 16.7945C303.109 16.7285 303.673 17.1357 303.694 17.796C303.716 18.5664 302.968 18.8636 302.566 19.0176C301.449 19.4358 298.413 20.0962 295.777 20.5034C295.875 24.6194 295.962 29.3518 295.94 30.7825C295.897 31.729 295.723 32.4994 295.029 32.5214C294.443 32.5434 294.01 31.9601 293.912 30.8816C293.619 28.3063 293.218 24.3113 292.893 20.8005H292.784C291.407 20.8445 289.715 20.6685 289.661 18.8856V18.9076Z"
      fill="white"
    />
    <path
      d="M305.245 18.3792C305.213 17.3337 305.787 17.0696 306.785 17.0035C307.089 17.0035 307.577 16.9815 308.195 16.9265C308.119 15.7819 308.054 14.7034 308.032 13.999C307.87 9.09059 309.269 6.63636 313.325 6.49329C317.89 6.33921 320.396 9.32171 320.45 11.1046C320.472 11.7649 320.146 12.2272 319.561 12.2492C319.322 12.2492 318.834 12.2051 318.454 11.908C316.936 10.7744 315.038 9.37673 313.151 9.43176C311.405 9.48679 311.101 10.7194 311.177 13.3387L311.286 16.6844C313.617 16.4973 316.047 16.3102 316.838 16.2882C318.693 16.2222 319.257 16.6294 319.279 17.2897C319.3 18.0601 318.552 18.3572 318.151 18.5113C317.034 18.9295 313.997 19.5898 311.362 19.997C311.459 24.1131 311.546 28.8455 311.524 30.2762C311.481 31.2227 311.307 31.993 310.624 32.0151C310.039 32.0371 309.605 31.4538 309.507 30.3752C309.214 27.7999 308.813 23.8049 308.488 20.2942H308.379C307.002 20.3382 305.31 20.1621 305.256 18.3792H305.245Z"
      fill="white"
    />
    <path
      d="M331.132 27.1396C331.013 23.4088 335.449 20.5033 339.743 20.3602C341.012 20.3162 342.845 20.5033 344.45 21.3507L344.428 20.8335C344.374 19.0176 342.487 17.1686 339.364 17.2677C337.748 17.3227 335.98 17.906 334.375 19.0396C333.572 19.6229 333.063 20.0631 332.282 20.0851C331.425 20.1181 330.547 19.6229 330.514 18.6764C330.492 18.0821 330.818 17.6199 331.685 16.8275C333.627 15.0556 335.785 13.867 338.735 13.7679C343.745 13.6029 346.879 16.7394 347.02 21.2737L347.281 29.3958C347.302 30.2322 347.02 30.8045 346.196 30.8265C345.513 30.8485 344.916 30.4523 344.884 29.6159C344.873 29.1977 344.971 28.1851 345.101 27.833C343.528 30.1221 340.578 31.4428 337.553 31.5418C333.844 31.6629 331.208 29.946 331.121 27.1506L331.132 27.1396ZM344.645 25.0486C344.591 23.3427 342.563 22.4733 340.546 22.5393C337.867 22.6274 334.624 24.4103 334.7 26.6114C334.755 28.2182 336.566 29.1646 338.702 29.0986C341.446 29.0106 344.721 27.2607 344.656 25.0596L344.645 25.0486Z"
      fill="white"
    />
    <path
      d="M351.738 28.8564C350.686 23.0675 349.656 17.5868 349.612 16.4752C349.591 15.6718 349.981 15.0335 350.773 15.0115C352.009 14.9675 352.92 16.5082 352.963 18.005C353.018 19.5678 353.028 21.0315 352.963 22.088C354.015 19.4687 356.141 16.123 357.301 14.8244C357.8 14.2521 358.332 13.8449 358.95 13.8229C360.078 13.7899 360.739 15.0555 361.314 16.6403C362.485 19.8759 363.657 25.2136 363.765 28.4162C363.808 29.8799 363.613 30.2321 362.832 30.2651C362.073 30.2871 361.694 29.99 361.563 29.3296C360.728 24.9274 360.36 23.0235 358.657 19.3146C358.397 18.7313 358.212 18.4232 357.876 18.4342C357.572 18.4342 357.41 18.7314 357.15 19.2596C355.306 22.9465 354.905 25.1916 354.048 29.5498C353.896 30.2871 353.462 30.5843 352.953 30.5953C352.128 30.6283 351.933 29.8249 351.76 28.8564H351.738Z"
      fill="white"
    />
    <path
      d="M382.364 27.888C382.267 27.1286 382.18 26.3583 382.115 25.5989C380.347 28.3062 377.278 30.1882 373.981 30.2982C369.209 30.4633 365.62 27.0186 365.468 22.3523C365.283 16.7725 369.925 12.4363 375.315 12.2492C377.853 12.1612 380.087 13.0966 381.616 14.6594C381.226 12.1281 380.922 9.31074 380.835 6.52635C380.792 5.12865 381.182 4.49033 382.071 4.45731C383.199 4.4243 384.316 5.95406 384.371 7.45081C384.577 13.7239 384.479 26.6994 384.457 27.855C384.425 28.9776 384.1 29.5389 383.449 29.5609C382.733 29.5829 382.505 28.9336 382.364 27.888V27.888ZM381.334 20.4923C381.236 17.5649 378.905 15.4848 375.781 15.5839C371.931 15.7159 368.504 19.0726 368.634 22.9356C368.732 25.797 370.999 27.811 374.122 27.7009C377.929 27.5689 381.464 24.2782 381.345 20.4813L381.334 20.4923Z"
      fill="white"
    />
    <path
      d="M397.992 11.875C397.981 11.4568 398.274 11.1376 398.545 10.7744C399.207 9.883 399.565 9.31072 399.521 8.15514V7.91302C399.087 8.4853 398.513 8.84849 397.862 8.8705C396.832 8.90351 396.224 8.05609 396.192 7.18665C396.148 5.72292 397.406 4.49031 398.849 4.44628C400.497 4.39126 401.202 6.04208 401.256 7.53883C401.321 9.66289 400.183 12.4913 398.838 12.5353C398.458 12.5463 398.003 12.3152 397.992 11.864V11.875ZM404.174 11.6659C404.163 11.2477 404.456 10.9285 404.727 10.5653C405.388 9.6739 405.746 9.10161 405.703 7.94603V7.70391C405.269 8.2762 404.694 8.63938 404.044 8.66139C403.013 8.69441 402.406 7.84698 402.373 6.97755C402.33 5.51382 403.588 4.2812 405.031 4.23718C406.679 4.18215 407.384 5.83298 407.438 7.32973C407.503 9.45379 406.364 12.2822 405.02 12.3262C404.64 12.3372 404.185 12.1061 404.174 11.6549V11.6659Z"
      fill="white"
    />
    <path
      d="M409.434 14.8795C409.401 13.834 409.976 13.5698 410.974 13.5038C411.277 13.5038 411.765 13.4818 412.383 13.4268C412.308 12.2822 412.242 11.2036 412.221 10.4993C412.058 5.59083 413.457 3.13661 417.513 2.99354C422.079 2.83946 424.584 5.82195 424.638 7.60484C424.66 8.26517 424.335 8.7274 423.749 8.74941C423.51 8.74941 423.022 8.70539 422.643 8.40824C421.125 7.27468 419.227 5.87698 417.34 5.932C415.594 5.98703 415.29 7.21965 415.366 9.83896L415.474 13.1846C417.806 12.9975 420.235 12.8104 421.027 12.7884C422.881 12.7224 423.445 13.1296 423.467 13.7899C423.489 14.5603 422.74 14.8575 422.339 15.0115C421.222 15.4298 418.186 16.0901 415.55 16.4973C415.648 20.6133 415.735 25.3457 415.713 26.7874C415.67 27.7339 415.496 28.5043 414.813 28.5263C414.227 28.5483 413.793 27.965 413.696 26.8865C413.403 24.3112 413.002 20.3162 412.676 16.8054H412.568C411.191 16.8495 409.51 16.6734 409.444 14.8905L409.434 14.8795Z"
      fill="white"
    />
    <path
      d="M427.642 26.3033C426.634 20.5144 425.69 15.0336 425.658 13.9221C425.636 13.1187 426.027 12.4803 426.818 12.4583C428.055 12.4143 428.966 13.9551 429.009 15.4518C429.052 16.8825 429.074 18.5224 429.009 19.777C430.484 14.5274 433.683 10.4113 437.262 10.2902C438.325 10.2572 439.084 11.4128 439.117 12.4583C439.149 13.5699 438.368 13.812 437.11 14.1972C431.633 15.848 430.462 21.1197 429.866 26.9966C429.79 27.734 429.345 28.0201 428.835 28.0421C428.011 28.0751 427.816 27.2717 427.642 26.3033V26.3033Z"
      fill="white"
    />
    <path
      d="M440.494 6.43814C440.45 5.11748 441.394 4.63323 442.121 4.60022C443.389 4.5562 444.539 5.84384 444.582 7.27456C444.626 8.49617 443.823 9.32158 442.934 9.3546C441.698 9.39862 440.548 7.9679 440.505 6.43814H440.494ZM442.793 26.3801C442.489 24.3991 441.427 16.0019 441.394 15.0224C441.34 13.3166 441.459 11.8749 442.739 11.8418C443.769 11.8088 444.756 12.7113 444.81 14.076C444.886 16.4091 444.995 23.0344 445.07 25.0925C445.125 26.8644 444.81 27.5137 443.986 27.5357C443.227 27.5577 442.945 27.2275 442.815 26.3911L442.793 26.3801Z"
      fill="white"
    />
    <path
      d="M447.728 19.7328C447.554 14.5052 452.131 9.25557 457.9 9.06848C463.117 8.89239 466.761 12.9864 466.88 16.6843C466.923 17.9389 466.533 18.6102 465.394 18.6543L458.562 18.8854C454.788 19.0175 452.38 18.9514 450.992 18.7863C450.916 22.9354 453.66 24.8063 457.195 24.6853C459.147 24.6193 461.403 23.9479 463.409 22.4182C463.984 21.9779 464.277 21.7578 464.765 21.7468C465.416 21.7248 465.915 22.088 465.926 22.6493C465.936 23.1335 465.611 23.5627 465.351 23.8599C463.431 26.017 459.928 27.4257 456.848 27.5247C451.838 27.6898 447.879 24.4432 447.728 19.7438V19.7328ZM457.922 16.0019L463.03 15.6608C463.377 15.6498 463.54 15.5397 463.529 15.2646C463.529 14.9454 463.334 14.6483 463.052 14.2631C462.086 13.0415 460.373 12.117 458.074 12.194C454.744 12.3041 452.196 14.4172 451.317 16.9594C452.478 16.6403 455.07 16.2 457.922 16.0019V16.0019Z"
      fill="white"
    />
    <path
      d="M471.131 24.8393C470.079 19.0504 469.049 13.5697 469.005 12.4581C468.984 11.6547 469.374 11.0164 470.155 10.9944C471.391 10.9504 472.302 12.4911 472.346 13.9879C472.4 15.5507 472.411 17.0254 472.346 18.0709C473.398 15.4516 475.523 12.1059 476.684 10.8073C477.183 10.235 477.714 9.8278 478.332 9.80579C479.46 9.76177 480.122 11.0384 480.696 12.6232C481.868 15.8588 483.039 21.1965 483.147 24.3991C483.191 25.8628 483.006 26.215 482.215 26.248C481.456 26.27 481.076 25.9729 480.946 25.3125C480.111 20.9103 479.742 19.0064 478.039 15.2975C477.779 14.7142 477.595 14.4061 477.259 14.4171C476.955 14.4171 476.792 14.7142 476.532 15.2425C474.688 18.9294 474.287 21.1745 473.43 25.5327C473.278 26.27 472.845 26.5672 472.335 26.5782C471.511 26.6002 471.315 25.8078 471.142 24.8393H471.131Z"
      fill="white"
    />
    <path
      d="M501.758 23.8818C501.66 23.1225 501.573 22.3521 501.508 21.5927C499.74 24.3 496.671 26.182 493.374 26.292C488.603 26.4571 485.013 23.0124 484.861 18.3461C484.677 12.7663 489.318 8.43011 494.708 8.25402C497.246 8.16597 499.48 9.10144 501.009 10.6532C500.619 8.12195 500.315 5.30454 500.228 2.52015C500.185 1.12245 500.576 0.484133 501.465 0.451117C502.593 0.407095 503.71 1.94787 503.764 3.44461C503.97 9.71775 503.872 22.6932 503.851 23.8488C503.818 24.9604 503.493 25.5327 502.842 25.5547C502.126 25.5767 501.899 24.9274 501.758 23.8818V23.8818ZM500.727 16.4861C500.63 13.5587 498.298 11.4786 495.175 11.5777C491.325 11.7097 487.898 15.0664 488.028 18.9294C488.125 21.7908 490.392 23.8048 493.515 23.6947C497.322 23.5627 500.857 20.272 500.738 16.4751L500.727 16.4861Z"
      fill="white"
    />
    <path
      d="M507.137 22.4733C506.746 22.0661 506.562 21.758 506.551 21.4168C506.54 20.9325 506.866 20.5033 507.419 20.4813C507.766 20.4703 508.113 20.6354 508.644 21.0316C510.228 22.1652 512.755 22.6384 514.403 22.5834C516.941 22.4953 518.752 21.1086 518.708 19.5459C518.665 18.3573 517.581 17.6309 515.824 17.6859C514.999 17.7079 512.538 17.9721 511.746 17.9941C509.273 18.0821 506.822 16.5193 506.735 13.867C506.638 10.8405 509.761 7.59388 513.709 7.46182C516.041 7.38478 518.86 8.40829 518.925 10.3563C518.947 10.8845 518.741 11.2037 518.166 11.2147C517.754 11.2257 517.06 11.0826 516.507 10.9175C515.704 10.6974 514.913 10.6204 514.023 10.6534C511.247 10.7415 509.393 11.853 509.436 13.3498C509.458 14.0101 509.815 14.4173 511.67 14.3513L515.412 14.2302C518.741 14.1201 521.225 16.3763 521.322 19.5128C521.431 22.8915 518.665 25.4228 514.338 25.5769C512.418 25.6429 509.75 25.2467 507.158 22.4733H507.137Z"
      fill="white"
    />
    <path
      d="M526.018 0.880327C526.029 1.29854 525.736 1.6177 525.465 1.98088C524.803 2.87232 524.445 3.44461 524.489 4.60019V4.87532C524.923 4.27002 525.497 3.89584 526.148 3.87382C527.178 3.84081 527.786 4.68823 527.818 5.55767C527.862 7.0214 526.604 8.25401 525.161 8.29804C523.513 8.35306 522.808 6.70224 522.754 5.20549C522.689 3.08143 523.827 0.253014 525.172 0.208992C525.552 0.197986 526.007 0.429102 526.018 0.880327V0.880327ZM532.2 0.671223C532.211 1.08943 531.918 1.40859 531.647 1.77177C530.985 2.66322 530.627 3.23551 530.671 4.39108V4.6332C531.104 4.06092 531.679 3.68673 532.33 3.66472C533.36 3.6317 533.967 4.47913 534 5.34856C534.043 6.81229 532.785 8.04491 531.343 8.08893C529.694 8.14396 528.99 6.49313 528.935 4.99639C528.87 2.87232 530.009 0.0439092 531.354 -0.000112835C531.733 -0.0111183 532.189 0.219997 532.2 0.671223V0.671223Z"
      fill="white"
    />
  </svg>
);

export default DiagonalText;
