import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: '270px',
    maxHeight: '270px',
    margin: '0 auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '172px',
      maxHeight: '172px'
    }
  },
  description: props => ({
    padding: props.isColumn ? '0 20px' : '0',
    '& > *': elementProps => ({
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '28.5px',
      color: '#1A1B34',
      textAlign: elementProps.centerDescription ? 'center' : 'left',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
        lineHeight: '24.5px'
      }
    })
  }),
  title: props => ({
    fontSize: '35px',
    fontWeight: 600,
    fontFamily: 'Inter',
    textAlign: props.isColumn ? 'center' : 'left',
    color: '#1A1B34',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      textAlign: 'center',
      marginTop: '27px'
    }
  }),
  subtitle: props => ({
    fontSize: '23px',
    fontFamily: 'Inter',
    textAlign: props.isColumn ? 'center' : 'left',
    marginBottom: '10px',
    color: '#8B8B8B',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      textAlign: 'center'
    }
  })
}));

const HostCard = ({ image, name, title, description, variant, centerDescription }) => {
  const isColumn = variant === 'column';
  const classes = useStyles({ isColumn, centerDescription });

  return (
    <Grid container direction={variant} alignItems={isColumn ? 'center' : 'flex-start'}>
      <Grid item xs={12} sm={isColumn ? 12 : 3}>
        <img className={classes.image} src={image?.url} alt={image?.alt || name} />
      </Grid>
      <Grid item xs={12} sm={isColumn ? 12 : 9}>
        <Typography className={classes.title}>{name}</Typography>
        <Typography className={classes.subtitle}>{title}</Typography>
        <RichText html={description} externalClassName={classes.description} />
      </Grid>
    </Grid>
  );
};

export default HostCard;

HostCard.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['row', 'column']).isRequired,
  centerDescription: PropTypes.bool.isRequired
};
