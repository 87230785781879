import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import Link from '~src/components/Link';
import Container from '~src/components/Container';
import { DateText, DiagonalText, WomanImage, YellowText } from './images';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '50px 40px',
    marginTop: '32px',
    borderRadius: '20px',
    background: 'linear-gradient(272.76deg, #011635 35.71%, #00347F 85.4%)',
    [theme.breakpoints.down('md')]: {
      padding: '30px 20px'
    }
  },
  subheading: {
    fontSize: '22px',
    lineHeight: '35px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#fff',
    marginTop: '20px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: '400'
    }
  },
  underline: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: '200',
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '100'
    }
  },
  dateText: {
    marginTop: '20px'
  },
  buttonEnded: {
    marginTop: '35px',
    textTransform: 'none',
    padding: '10px 24px',
    fontSize: '21px'
  },
  paragraph: {
    color: '#fff',
    fontSize: '15px',
    textAlign: 'center',
    lineHeight: '25px',
    [theme.breakpoints.up('md')]: {
      lineHeight: '36px',
      fontSize: '24px'
    }
  }
}));

const KidsShow = ({ isKidsEventLive }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;

  return (
    <>
      <Box component={Container} background="#458FCE" py="10px" mb="60px" mt="50px" width="100%">
        <Typography component="p" className={classes.paragraph}>
          Not sure who will watch the kids? All attendees will receive FREE access to a virtual,
          LIVE, Tisha B’Av themed puppet show from “edutainer” <strong>Sari Kopitnikoff</strong>, 10
          - 11 AM ET.
        </Typography>
      </Box>
      <Container center className={classes.root}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            lg={7}
          >
            <YellowText />
            <DiagonalText />
            <Hidden mdUp>
              <WomanImage />
            </Hidden>
            <Typography variant="h5" className={classes.subheading} gutterBottom>
              Join Sari and Ori (her orangutan friend) for an interactive, educational puppet show.
              Make crafts, play games and learn a ton.
            </Typography>
            <Typography component="p" className={classes.underline}>
              Recommended for ages 3-7
            </Typography>
            {isKidsEventLive && isPrivilegedMember && (
              <Link
                to="https://us02web.zoom.us/w/88135149118?tk=JuaRTBqh_GqexcMU3nLujFlcOVBv-uNfdoLCacvV6UA.DQMAAAAUhUOmPhZmekxPZmhKUVJwLUVBcHI0V1FWT3h3AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
                underline="none"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonEnded}
                >
                  Join Puppet Show Now
                </Button>
              </Link>
            )}
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            lg={5}
          >
            <Hidden mdDown>
              <WomanImage />
            </Hidden>
            <DateText className={classes.dateText} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

KidsShow.propTypes = {
  isKidsEventLive: PropTypes.bool
};

KidsShow.defaultProps = {
  isKidsEventLive: false
};

export default KidsShow;
