import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Link from '~components/Link';
import { useTestId } from '~utils/set-testid';
import { getResourceTypeByRelativeUrl } from '~utils/playlist-helpers';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100% !important',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative'
  },
  media: {
    aspectRatio: '16 / 9',
    borderRadius: 15,
    minHeight: '100px'
  },
  description: props => ({
    color: props.variant === 'dark' ? '#FFFFFF' : '#000000',
    height: '100%',
    paddingLeft: 2,
    paddingRight: 2
  }),
  title: {
    maxWidth: 235,
    fontSize: 16,
    fontWeight: 500
  },
  duration: {
    fontSize: 14,
    marginBottom: 0,
    marginLeft: 3,
    color: '#FFFFFF'
  },
  durationIcon: {
    fontSize: 13,
    color: '#FFFFFF'
  }
});

const CategoryCard = props => {
  const { title, subtitle, image, duration, link, label, labelOnImage, variant } = props;
  const classes = useStyles({ variant });
  const cardType = getResourceTypeByRelativeUrl(link);
  const testId = useTestId(`${cardType}-item`);

  return (
    <Link to={link} underline="none" {...testId}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia className={classes.media} image={image} title={title} />
          {duration && (
            <Box
              component="span"
              position="absolute"
              display="flex"
              flexDirection="row"
              alignItems="center"
              right={11}
              bottom={7}
            >
              <WatchLaterIcon className={classes.durationIcon} />
              <Typography
                gutterBottom
                variant="subtitle2"
                component="p"
                className={classes.duration}
              >
                {duration}
              </Typography>
            </Box>
          )}
          {labelOnImage && label && (
            <Box
              component="span"
              position="absolute"
              display="flex"
              flexDirection="row"
              alignItems="center"
              left={11}
              bottom={7}
            >
              <Box component="div" display="flex" height="fit-content" alignItems="center">
                <Box
                  component="span"
                  left={15}
                  top={15}
                  fontSize={12}
                  fontWeight={600}
                  borderRadius={32}
                  bgcolor="#000000"
                  color="#FFFFFF"
                  padding="4px 11px 4px 11px"
                >
                  {label}
                </Box>
              </Box>
            </Box>
          )}
        </CardActionArea>
        <CardContent className={classes.description}>
          <Box component="div" display="flex" justifyContent="space-between">
            <Typography gutterBottom variant="subtitle2" component="p" className={classes.title}>
              {title}
            </Typography>
            {!labelOnImage && label && (
              <Box component="div" display="flex" height="fit-content" alignItems="center">
                <Box
                  component="span"
                  left={15}
                  top={15}
                  fontSize={12}
                  fontWeight={600}
                  borderRadius={32}
                  bgcolor={variant === 'dark' ? '#FFFFFF' : '#000000'}
                  color={variant === 'dark' ? '#000000' : '#FFFFFF'}
                  padding="4px 11px 4px 11px"
                >
                  {label}
                </Box>
              </Box>
            )}
          </Box>
          <Typography
            variant="body2"
            color={variant === 'dark' ? 'inherit' : 'textSecondary'}
            component="p"
          >
            {subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  duration: PropTypes.string,
  labelOnImage: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark'])
};

CategoryCard.defaultProps = {
  subtitle: '',
  label: '',
  duration: '',
  labelOnImage: false,
  variant: 'light'
};

export default CategoryCard;
