import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '68px',
    textAlign: 'center',
    borderRadius: '36px',
    border: '2px solid #FFF',
    padding: '16px 34px 27px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 20px'
    }
  },
  heading: {
    fontSize: '40px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#FFF',
    minWidth: '320px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      minWidth: '230px'
    }
  },
  subheading: {
    fontSize: '28px',
    fontFamily: 'Roboto',
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px'
    }
  },
  divider: {
    maxWidth: '75%',
    margin: '10px auto',
    backgroundColor: '#FFF'
  }
}));

const DateSection = ({ dateOnly }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!dateOnly && (
        <>
          <Typography variant="h3" className={classes.heading}>
            TISHA B’AV MORNING
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      <Typography variant="h3" className={classes.heading}>
        JULY 27, 2023
      </Typography>
      <Typography variant="h4" className={classes.subheading}>
        10AM - 1PM ET
      </Typography>
    </div>
  );
};

DateSection.propTypes = {
  dateOnly: PropTypes.bool
};

DateSection.defaultProps = {
  dateOnly: false
};

export default DateSection;
