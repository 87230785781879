import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import LoadingSpinner from '~src/components/LoadingSpinner';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import { isBotAgent } from '~utils/browser-helpers';
import PostEvent from './PostEvent';

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    justifyContent: 'flex-start !important',
    alignItems: 'center !important',
    padding: props.isEventEnded ? '30px 20px 0px' : '30px 20px 80px',
    color: '#fff',
    [theme.breakpoints.down('xs')]: mobileProps => ({
      padding: mobileProps.isEventEnded ? '45px 20px 0px' : '45px 20px 80px'
    })
  }),
  textBlueBackground: {
    fontSize: '22px',
    lineHeight: '29px',
    fontWeight: '400',
    fontFamily: 'Roboto Slab',
    backgroundColor: '#458FCE',
    padding: '0 20px',
    borderRadius: '5px',
    marginBottom: '10px'
  },
  heading: {
    fontSize: '54px',
    fontWeight: '600',
    fontFamily: 'Roboto Slab',
    '& span': {
      color: '#031B42',
      borderRadius: '5px',
      padding: '0 15px',
      background: 'linear-gradient(90deg, #82C9F4 0%, #3F8FBC 97%)'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '38px'
    }
  },
  subheading: {
    fontSize: '32px',
    fontFamily: 'Roboto Slab',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px'
    }
  },
  imageLive: {
    display: 'flex',
    maxWidth: '100%',
    marginBottom: '10px',
    marginTop: '-60px',
    zIndex: '1',
    width: '900px',
    height: '400px',
    [theme.breakpoints.up('md')]: {
      marginTop: '-120px'
    }
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '10px'
  },
  paragraph: {
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '20px',
    fontSize: '18px',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '35px'
    }
  },
  textRedBackground: {
    backgroundColor: '#FB5C46',
    borderRadius: '5px',
    padding: '0px 5px'
  },
  paragraphLink: {
    fontWeight: '800',
    color: '#0777E5',
    fontSize: '17px'
  },
  button: {
    backgroundColor: '#0777E5',
    marginBottom: '30px',
    fontSize: '26px',
    lineHeight: '31px',
    fontWeight: '400',
    textTransform: 'none',
    padding: '20px 32px',
    boxShadow: 'unset',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '28px',
      padding: '10px 20px'
    }
  },
  shapeDivider: {
    display: 'flex',
    marginTop: '-5px'
  }
}));

const TishaBavHero = ({ isLoading, postEventPlaylistUrl, bottomShapeDividerSrc }) => {
  const classes = useStyles();
  // container height should be the content height when hero is merged with below section
  const containerProps = bottomShapeDividerSrc ? { size: 100 } : {};
  const isBot = isBotAgent();

  return (
    <>
      <Container background="#000014" {...containerProps} className={classes.root}>
        {isLoading && !isBot ? (
          <LoadingSpinner />
        ) : (
          <PostEvent playlistUrl={postEventPlaylistUrl} />
        )}
      </Container>
      {bottomShapeDividerSrc && (
        <BottomShapeDivider
          src={bottomShapeDividerSrc}
          bgColor="transparent"
          className={classes.shapeDivider}
        />
      )}
    </>
  );
};

TishaBavHero.propTypes = {
  postEventPlaylistUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  bottomShapeDividerSrc: PropTypes.string
};

TishaBavHero.defaultProps = {
  bottomShapeDividerSrc: ''
};

export default TishaBavHero;
