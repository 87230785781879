import { get } from 'lodash';
import { extractSeoData } from '~src/utils/unfurl-helpers';
import { extractResource } from './landing-page';

const CUSTOM_SLICE_TYPENAME = {
  CATEGORY_CAROUSEL: 'PrismicTishaBavEventDataBodyCategoryCarousel'
};

const extractCategoryCarousels = prismicData => {
  const items = get(prismicData, 'prismicTishaBavEvent.data.body', []);

  return items
    .filter(item => get(item, '__typename') === CUSTOM_SLICE_TYPENAME.CATEGORY_CAROUSEL)
    .map(item => ({
      category: get(item, 'primary.category'),
      cards: get(item, 'items', []).map(carouselItem => ({
        id: get(carouselItem, 'resource.id'),
        label: carouselItem?.label || '',
        link: get(carouselItem, 'resource.url'),
        ...extractResource(carouselItem)
      }))
    }));
};

export default prismicData => ({
  currentEventPeriod: get(prismicData, 'prismicTishaBavEvent.data.current_event_period', ''),
  eventLaunchTime: get(prismicData, 'prismicTishaBavEvent.data.event_launch', ''),
  eventEndTime: get(prismicData, 'prismicTishaBavEvent.data.event_end', ''),
  subscribeCtaText: get(prismicData, 'prismicTishaBavEvent.data.subscribe_cta_text', ''),
  postEventPlaylistUrl: get(prismicData, 'prismicTishaBavEvent.data.post_event_playlist_url', ''),
  categoryCarousels: extractCategoryCarousels(prismicData),
  testimonials: get(prismicData, 'prismicTishaBavEvent.data.testimonials', []).map(testimonial => ({
    testimonial: get(testimonial, 'testimonial.html', ''),
    author: get(testimonial, 'author', ''),
    designation: get(testimonial, 'designation', '')
  })),
  sectionTitles: {
    categoryCarousels: get(
      prismicData,
      'prismicTishaBavEvent.data.category_carousels_section_title',
      ''
    ),
    testimonials: get(prismicData, 'prismicTishaBavEvent.data.testimonial_section_title', '')
  },
  hosts: get(prismicData, 'prismicTishaBavEvent.data.hosts', []).map(host => ({
    name: get(host, 'host_name', ''),
    title: get(host, 'host_title', ''),
    description: get(host, 'host_description.html', ''),
    image: get(host, 'host_avatar', null)
  })),
  preEventSlices: get(prismicData, 'prismicTishaBavEvent.data.body1', []),
  seo: extractSeoData(prismicData, 'prismicTishaBavEvent')
});
