import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BaseSlider from './BaseSlider';
import CategoryCard from './CategoryCard';
import NavigationButtons from './NavigationButtons';
import CategoryButtons from './CategoryButtons';

const CategoryCarousel = ({ carousels, variant }) => {
  const theme = useTheme();
  const sliderRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);

  const isExtraSmallerScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [cardsToShow, setCardsToShow] = useState(3);

  const selectedCards = useMemo(
    () => (carousels[selectedIndex] ? carousels[selectedIndex].cards : []),
    [carousels, selectedIndex]
  );

  useEffect(() => {
    setSelectedSlideIndex(0);
    if (sliderRef?.current) {
      sliderRef.current.slickGoTo(0, true);
    }
  }, [selectedIndex, sliderRef]);

  useEffect(() => {
    if (isExtraSmallerScreen) {
      setCardsToShow(1);
    } else if (isSmallerScreen) {
      setCardsToShow(2);
    } else {
      setCardsToShow(3);
    }
  }, [isExtraSmallerScreen, isSmallerScreen]);

  const onChangeCategory = index => {
    setSelectedIndex(index);
  };

  const beforeSlideChange = nextSlide => {
    setSelectedSlideIndex(nextSlide);
  };

  return (
    <>
      <Box component="div" display="flex" justifyContent="space-between" width="100%">
        {carousels.length > 1 ? (
          <CategoryButtons
            carousels={carousels}
            selectedIndex={selectedIndex}
            onChangeCategory={onChangeCategory}
          />
        ) : (
          <div />
        )}

        {!isExtraSmallerScreen && (
          <NavigationButtons
            sliderRef={sliderRef}
            cardsToShow={cardsToShow}
            selectedSlideIndex={selectedSlideIndex}
            selectedCarousel={carousels[selectedIndex]}
            variant={variant}
          />
        )}
      </Box>

      <Grid container alignItems="stretch" gap={2}>
        <BaseSlider ref={sliderRef} beforeSlideChange={beforeSlideChange}>
          {selectedCards.map(card => (
            <CategoryCard
              key={card?.id}
              {...card}
              labelOnImage={isMediumScreen}
              variant={variant}
            />
          ))}
        </BaseSlider>
      </Grid>
    </>
  );
};

CategoryCarousel.propTypes = {
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          label: PropTypes.string,
          link: PropTypes.string,
          image: PropTypes.string,
          duration: PropTypes.string
        })
      )
    })
  ).isRequired,
  variant: PropTypes.oneOf(['light', 'dark'])
};

CategoryCarousel.defaultProps = {
  variant: 'light'
};

export default CategoryCarousel;
