import React from 'react';

const YellowText = () => (
  <svg
    width="487"
    height="203"
    viewBox="0 0 487 203"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maxWidth: '100%', height: 'fit-content' }}
  >
    <g filter="url(#filter0_d_133_230)">
      <path
        d="M53.3403 51.8774L44.9428 51.1986L33.2365 51.4402C32.9398 55.5361 33.4875 63.5093 33.6244 69.7221C33.6928 73.9791 34.2862 78.3166 35.3587 91.3407L35.8721 95.9428C33.1224 97.9102 27.7712 98.4509 19.4764 98.3129L18.9059 92.2036L19.0656 69.2734L19.1455 51.7738C15.5286 51.6358 11.9231 51.7738 7.62169 52.2801L0.798696 53.7643C0.193983 51.1295 0.0456566 46.9531 1.78287e-05 45.63C-0.0228016 45.1007 0.524864 40.6367 1.07253 38.5772L6.64046 39.0835C12.1856 38.9684 16.5783 38.6347 26.4363 38.3701C33.4761 38.209 45.0683 36.9895 45.0683 36.9895L52.7927 36.2646C53.4887 38.5542 53.7511 48.2187 53.3517 51.8774H53.3403Z"
        fill="url(#paint0_linear_133_230)"
      />
      <path
        d="M93.491 100.706C91.8252 100.948 88.2882 100.706 86.3372 100.165L86.2801 93.5957C86.2117 89.2467 86.9191 84.3339 86.0861 78.3281C85.7439 71.2293 84.5801 67.9848 83.3935 64.5677C82.8002 62.8995 82.2297 61.4958 80.9062 61.5534C77.7343 61.6684 75.4865 67.7892 74.6993 70.3089L76.0114 79.9504L78.1222 94.6197L78.841 100.016C74.1402 101.695 69.679 102.478 64.5561 102.213L63.7346 96.472L62.2057 85.7721C61.2815 79.6742 60.3231 72.5179 59.2506 64.6483C58.7485 60.3108 55.9988 37.4957 51.355 15.9002C55.3598 14.6001 58.6002 13.8638 60.4372 13.7947C61.6694 13.7487 62.9929 13.7027 67.5112 14.6001L68.2186 19.5474C69.0287 24.9319 69.2112 32.6635 70.4663 40.6942C71.2535 45.4574 71.4703 51.7623 72.0636 56.0078C73.5811 51.6933 79.7994 48.0001 82.6861 47.6319C86.9077 47.2983 92.6581 50.5543 95.7159 57.2043C97.724 61.5764 100.28 71.517 100.839 87.3138C100.907 89.1776 100.976 91.3061 100.964 93.3541C100.907 94.0674 100.896 98.8651 101.101 99.659C101.101 99.659 95.2367 100.844 93.4796 100.729L93.491 100.706Z"
        fill="url(#paint1_linear_133_230)"
      />
      <path
        d="M143.009 96.0808C138.879 98.8996 135.296 99.8201 132.124 99.6705C130.481 97.9562 129.477 92.0425 129.477 92.0425C127.572 92.8248 123.59 95.0108 121.388 95.0914C112.328 95.4135 104.422 91.2601 104.079 81.5036C104.022 79.8123 104.136 78.2131 104.524 76.6023C105.437 72.656 107.593 68.6751 110.754 65.8103C113.652 63.2216 117.394 61.933 121.799 61.7719C123.476 61.7144 125.678 61.9905 127.195 62.2897L127.115 59.9771L126.807 56.1689C126.054 52.3721 125.358 52.6712 123.613 53.1775C122.825 53.3846 118.033 54.9723 117.178 55.4555C115.455 56.583 113.207 57.7336 111.085 59.9426C109.373 58.8496 108.483 56.1228 107.582 53.2235C106.989 51.3826 106.863 47.747 107.251 46.4008C112.134 42.6731 117.44 40.7057 123.339 40.4871C126.157 40.3835 128.633 40.7402 130.687 41.465C135.673 42.7997 139.301 45.8601 140.784 50.3357C141.754 52.9704 142.245 56.5945 142.393 61.0241C142.473 63.1526 142.393 65.9139 142.473 68.399C142.519 72.3108 142.587 74.0826 142.655 76.1191C142.975 85.0817 144.036 90.1901 146.478 91.9734C146.478 91.9734 144.732 94.9648 143.009 96.0923V96.0808ZM122.403 71.4134C120.555 71.4824 119.334 76.7634 119.425 79.2485C119.562 83.0683 120.68 84.7136 122.255 84.656C124.366 84.5755 127.412 83.402 128.108 80.7097L127.777 73.8871L127.047 73.2082C125.587 71.8391 123.635 71.3789 122.403 71.4249V71.4134Z"
        fill="url(#paint2_linear_133_230)"
      />
      <path
        d="M172.309 62.681C172.423 65.8795 172.446 71.6436 172.72 79.2832C172.823 82.2055 173.542 85.2084 175.572 85.1279C177.067 84.8978 181.312 78.0751 181.631 77.1777C186.492 77.7069 190.245 79.1796 193.28 82.7118C189.823 94.3897 179.007 100.188 171.088 100.476C170.119 100.511 168.886 100.476 167.825 100.234H167.917C161.185 99.0493 157.557 93.6763 157.294 84.0004C157.237 82.3091 157.1 78.4088 156.975 72.38L156.701 64.6599C153.894 65.0281 150.996 65.3042 149.764 65.4423L149.284 51.866C151.566 51.7854 155.012 51.9235 155.982 51.7969C155.879 48.9551 155.788 46.3894 155.902 44.6867C155.879 39.1756 156.301 31.1679 156.541 23.0797C162.417 22.2513 167.54 22.5964 171.819 24.1266V29.5456L171.693 33.1008C171.716 36.3913 171.465 39.0606 171.408 44.8362C171.305 46.8842 171.271 45.9062 171.408 49.726C174.123 49.3578 177.546 49.0587 180.273 48.7826L187.381 48.0807C188.796 50.7845 188.808 53.5343 188.876 55.4902C188.922 56.6407 188.796 60.5525 188.579 61.8986L179.874 62.3013C178.117 62.3703 174.42 62.4969 172.309 62.658V62.681Z"
        fill="url(#paint3_linear_133_230)"
      />
      <path
        d="M252.588 66.006C253.455 68.1114 254.927 72.4029 255.178 74.4394C257.494 87.4174 254.185 101.132 241.064 103.64C239.49 103.962 237.824 104.204 235.884 104.181C231.822 104.066 228.445 103.203 225.741 101.442C224.577 100.867 220.413 92.6638 220.219 87.2448C224.748 85.6571 232.279 84.495 232.279 84.495C232.689 88.7405 234.88 90.892 234.88 90.892C235.953 91.3867 238.428 91.6513 239.387 91.1681C242.068 89.822 241.532 84.6101 240.311 77.6264C239.855 74.6235 238.668 71.1143 237.025 66.8229C235.759 63.4978 234.503 60.4259 233.248 57.2734C232.096 54.6502 231.617 53.5112 229.666 48.2533L218.062 51.5093C216.351 47.8391 215.233 43.7086 214.628 39.1986L221.439 37.3577L252.611 29.5686C254.779 33.3999 255.885 37.3577 255.771 41.7988L243.962 44.2724C245.811 49.3578 247.944 54.6157 252.576 65.9945L252.588 66.006Z"
        fill="url(#paint4_linear_133_230)"
      />
      <path
        d="M290.776 77.1546C291.746 77.1201 295.511 76.4528 300.68 75.5554C300.189 81.6992 298.455 87.3598 295.477 92.7098L289.19 91.7779C284.946 91.2256 280.587 90.2247 277.187 88.5679H277.278C271.368 85.9332 267.375 82.7002 265.036 78.6963C261.853 73.3003 260.415 67.5822 260.21 61.726C260.084 58.2629 260.655 54.2475 261.773 51.0145C265.469 41.0164 272.053 35.4478 280.872 35.7584H280.781C294.267 36.0691 301.216 45.5955 301.524 64.2456L276.788 69.6646C279.184 75.1757 283.759 77.4077 290.799 77.1546H290.776ZM284.763 56.491C284.501 53.9253 284.524 52.3261 283.759 50.3932C282.903 48.4718 281.819 47.885 280.325 47.9426C279.093 47.8966 276.868 49.6684 275.909 52.6367C275.282 54.7882 274.996 56.8477 275.065 58.5275C278.214 57.7911 281.454 57.2274 284.775 56.491H284.763Z"
        fill="url(#paint5_linear_133_230)"
      />
      <path
        d="M363.342 64.0616C362.418 67.7433 359.417 77.4423 356.485 86.7847L348.646 89.3734L343.067 78.6504C341.081 74.7271 338.024 70.4816 336.529 68.3186L336.346 68.146C333.095 73.2429 327.846 84.173 324.868 89.615L317.726 89.4309L311.633 66.8229C309.534 59.7011 307.16 55.076 303.658 48.0117C305.369 46.7116 307.788 45.1124 309.066 44.1689C310.618 43.3175 313.584 42.4086 318.022 40.9129C319.768 47.7816 320.19 49.9906 324.149 64.4988L324.229 64.3148L334.076 43.9733C336.175 43.6282 338.549 43.283 340.043 43.3981L350.209 64.2687C351.373 59.7817 352.286 53.2696 353.769 45.4C354.134 43.26 354.203 40.4067 354.157 38.9915C359.234 38.1862 365.11 39.8429 368.248 41.2351L367.598 45.3425C366.856 49.1853 365.578 55.6282 363.33 64.0616H363.342Z"
        fill="url(#paint6_linear_133_230)"
      />
      <path
        d="M377.843 35.6434C373.713 35.793 368.921 32.5945 368.773 28.1534C368.613 23.7124 372.983 20.2723 377.296 20.1227C381.962 19.9501 385.865 23.1026 386.367 27.5206C386.264 32.1458 382.419 35.4823 377.843 35.6434V35.6434ZM383.662 87.8431C382.601 87.8776 381.369 87.9236 376.326 87.2218C375.562 78.0981 373.61 60.4949 371.272 44.2264C375.972 42.5466 380.958 41.7412 386.093 42.2705L386.903 47.8391L386.811 47.6665C387.131 49.2542 387.804 55.8007 388.797 63.7624C389.287 67.6512 390.383 76.0501 391.181 86.3244C391.181 86.3244 386.207 87.5669 383.662 87.8431V87.8431Z"
        fill="url(#paint7_linear_133_230)"
      />
      <path
        d="M416.283 51.4862C424.121 53.8678 434.093 56.9743 434.538 69.4805C434.903 79.6858 426.791 87.0837 415.267 87.4979C398.815 88.0962 395.848 79.053 395.745 65.9944L410.658 66.5237C409.642 70.2859 410.521 72.656 410.738 73.6225C410.954 74.773 412.232 76.1421 414.434 76.0616C417.515 75.9465 419.786 72.9321 419.66 69.4805C419.5 65.1315 415.233 63.8659 411.046 62.5083C403.39 60.1152 395.631 57.561 395.22 48.6904C394.627 36.8974 405.752 32.583 414.902 32.2608C423.345 31.9617 430.773 32.4909 431.868 48.7019L421.166 49.887C420.733 45.1928 418.017 43.0758 416.077 43.0528C412.814 43.0873 410.179 45.5725 410.441 48.1382C410.772 49.8985 412.814 50.4507 416.283 51.4862Z"
        fill="url(#paint8_linear_133_230)"
      />
      <path
        d="M474.803 86.9571C473.137 87.1987 469.6 86.9571 467.649 86.4163L467.592 79.8468C467.524 75.4978 468.231 70.585 467.398 64.5792C467.056 57.4804 465.881 54.2359 464.705 50.8188C464.112 49.1506 463.542 47.7469 462.218 47.8045C459.046 47.9195 456.799 54.0403 456.011 56.56L457.323 66.2015L459.434 80.8708L460.153 86.2668C455.452 87.9465 450.991 88.7289 445.868 88.4643L445.047 82.7231L443.518 72.0232C442.593 65.9253 441.635 58.769 440.563 50.8994C440.061 46.5619 437.311 23.7468 432.667 2.15132C436.672 0.851214 439.912 0.114873 441.749 0.0458407C442.981 -0.000180561 444.305 -0.0462019 448.823 0.862719L449.531 5.81001C450.341 11.1945 450.523 18.9261 451.778 26.9568C452.566 31.7315 452.782 38.0249 453.376 42.2704C454.893 37.9559 461.111 34.2627 463.998 33.8945C468.22 33.5609 473.97 36.8169 477.028 43.4669C479.036 47.839 481.592 57.7796 482.151 73.5764C482.219 75.4403 482.288 77.5687 482.276 79.6167C482.219 80.33 482.208 85.1277 482.413 85.9216C482.413 85.9216 476.549 87.1067 474.792 86.9916L474.803 86.9571Z"
        fill="url(#paint9_linear_133_230)"
      />
      <path
        d="M75.7033 147.072H75.7946L65.7997 155.955H65.891C65.2064 156.507 63.2325 157.91 60.4714 159.786L60.5513 159.694L59.262 160.626C63.6433 164.998 66.3017 167.828 67.5682 168.944C71.767 173.051 75.6805 176.469 79.1148 179.103L86.2687 184.706C85.7439 187.387 85.1277 189.815 84.2264 191.713C83.1653 193.968 81.4766 195.993 79.7652 197.466L69.6904 189.032C66.5071 186.306 59.5472 178.919 56.1243 174.237C56.1243 179.299 56.5921 187.548 56.4552 191.103C56.4095 192.265 56.7404 196.511 56.672 197.316C51.355 198.927 46.4146 198.754 41.6682 196.522L41.8394 191.541C41.5769 181.692 41.4857 171.74 41.2232 161.799C41.132 159.222 41.1548 154.965 41.3031 149.178C41.3601 143.31 40.9494 129.377 40.8581 126.627C46.3576 125.097 51.3665 125.005 55.8048 125.822L55.2914 146.187C59.1479 143.207 64.8185 139.007 70.0898 133.749L74.6879 129.228C79.3773 132.254 82.5834 135.694 84.3747 139.099L80.1075 142.896L75.6691 147.049L75.7033 147.072Z"
        fill="url(#paint10_linear_133_230)"
      />
      <path
        d="M97.4844 140.066C93.3541 140.215 88.5621 137.017 88.4137 132.576C88.254 128.135 92.6239 124.695 96.9368 124.534C101.603 124.361 105.494 127.513 106.007 131.932C105.905 136.557 102.06 139.893 97.4844 140.054V140.066ZM103.303 192.265C102.242 192.3 101.01 192.346 95.967 191.644C95.2025 182.52 93.2515 164.917 90.9239 148.649C95.6247 146.969 100.611 146.164 105.745 146.693L106.555 152.261L106.464 152.089C106.783 153.677 107.457 160.223 108.449 168.185C108.94 172.074 110.035 180.473 110.834 190.747C110.834 190.747 105.859 191.989 103.315 192.265H103.303Z"
        fill="url(#paint11_linear_133_230)"
      />
      <path
        d="M146.546 191.955C145.257 190.402 143.808 184.327 143.602 181.048C140.522 183.648 135.855 186.041 132.25 186.26C126.351 186.386 122.403 184.304 119.106 180.956C115.991 177.688 114.257 173.397 113.994 168.53C113.549 163.307 114.69 158.382 117.326 153.216C120.966 146.417 126.67 143.552 134.84 142.896C136.506 142.747 138.639 143.287 139.894 143.874C139.529 138.651 139.004 133.784 138.057 129.193C137.281 124.775 136.631 121.427 136.46 118.861C136.175 115.939 136.403 112.292 137.03 107.736C143.591 106.344 148.623 106.7 152.856 109.209L152.24 114.029C152.034 115.721 152.023 117.941 152.205 120.426C152.354 122.106 152.719 125.028 153.301 128.825C154.259 135.809 155.343 154.16 156.404 164.066C157.294 171.855 158.606 181.404 161.128 187.79L158.538 189.217C156.473 190.54 153.768 191.437 146.569 191.955H146.546ZM142.302 169.094C142.153 167.322 141.834 165.918 141.8 165.021C141.56 163.249 141.252 159.625 140.887 156.61C139.096 155.702 137.943 155.471 137.59 155.575C135.581 156.001 133.493 156.703 131.565 159.613C129.728 162.34 128.952 165.389 129.158 168.668C129.454 172.028 130.824 173.489 133.026 173.408C135.49 173.316 138.525 171.878 142.302 169.071V169.094Z"
        fill="url(#paint12_linear_133_230)"
      />
      <path
        d="M184.141 154.171C191.98 156.553 201.952 159.659 202.397 172.166C202.762 182.371 194.649 189.769 183.126 190.183C166.673 190.781 163.706 181.738 163.604 168.679L178.516 169.209C177.501 172.982 178.379 175.341 178.596 176.308C178.813 177.458 180.091 178.827 182.293 178.747C185.373 178.632 187.644 175.617 187.518 172.166C187.37 167.817 183.091 166.551 178.904 165.193C171.248 162.8 163.49 160.246 163.09 151.375C162.497 139.583 173.621 135.268 182.772 134.946C191.215 134.647 198.643 135.176 199.738 151.387L189.036 152.572C188.602 147.878 185.887 145.761 183.947 145.738C180.684 145.761 178.048 148.258 178.311 150.823C178.642 152.584 180.684 153.136 184.152 154.171H184.141Z"
        fill="url(#paint13_linear_133_230)"
      />
      <path
        d="M210.726 146.025L203.8 139.698C206.207 137.926 211.502 128.227 211.251 126.098V125.926C207.531 125.615 205.181 121.519 204.873 117.63C204.37 113.293 207.223 109.37 212.403 108.737C217.754 108.012 222.158 110.428 223.196 120.069C223.961 126.789 217.651 139.997 210.726 146.014V146.025Z"
        fill="url(#paint14_linear_133_230)"
      />
      <path
        d="M293.058 179.828L293.138 179.736C291.518 183.614 288.985 186.812 285.22 189.884C281.877 192.576 277.963 194.049 273.308 194.221C268.47 194.394 264.203 193.221 260.141 190.517H260.232C258.167 189.251 252.337 181.646 252.417 173.742C252.382 172.764 252.44 169.474 252.417 168.496C257.813 166.528 262.64 166.171 267.181 167.426V172.672C267.101 175.249 267.398 178.62 267.5 178.965C268.185 180.898 271.277 181.048 271.893 181.117C272.863 181.174 274.323 180.047 276.183 177.941C277.541 176.469 278.111 175.295 279.093 173.04C279.652 171.51 280.017 169.37 279.925 166.701C279.868 165.194 279.446 162.985 279.161 162.639C277.872 161.086 275.476 160.637 271.847 160.154C266.097 159.567 260.232 158.083 256.741 153.953C254.63 151.456 253.752 149.086 253.307 146.704C253.067 145.025 252.999 142.988 252.919 140.86L252.873 139.445C252.668 133.588 253.021 126.11 256.981 121.082C260.871 116.411 266.359 114.789 272.361 114.927C279.423 115.295 284.421 116.975 286.714 119.655L286.623 119.575C289.099 122.417 290.536 125.558 290.924 129.101L291.255 138.501L276.56 138.858L276.252 130.16C276.137 129.458 276.023 128.745 275.932 128.664C274.574 127.732 272.988 127.709 272.201 127.732C270.878 127.778 269.759 128.526 268.584 129.999C267.569 131.276 267.352 135.107 267.466 138.214C267.523 139.893 267.865 141.93 268.447 143.69C269.143 145.888 270.855 146.889 273.331 147.153C282.264 148.166 288.688 150.513 291.541 153.78C293.937 156.622 295.488 160.741 295.682 166.16C295.853 170.946 294.963 175.606 293.081 179.851L293.058 179.828Z"
        fill="url(#paint15_linear_133_230)"
      />
      <path
        d="M335.924 186.283C334.258 186.524 330.733 186.283 328.77 185.742L328.713 179.172C328.645 174.823 329.352 169.911 328.519 163.905C328.177 156.806 327.002 153.561 325.827 150.144C325.245 148.476 324.663 147.072 323.339 147.13C320.167 147.245 317.92 153.366 317.132 155.886L318.445 165.527L320.555 180.196L321.274 185.592C316.573 187.272 312.112 188.054 306.989 187.79L306.168 182.049L304.639 171.349C303.715 165.251 302.756 158.095 301.684 150.225C301.182 145.887 298.432 123.072 293.788 101.477C297.793 100.177 301.033 99.4404 302.87 99.3714C304.103 99.3254 305.426 99.2794 309.944 100.177L310.652 105.124C311.462 110.509 311.644 118.24 312.899 126.271C313.687 131.046 313.904 137.339 314.497 141.584C316.014 137.27 322.233 133.577 325.119 133.209C329.341 132.875 335.091 136.131 338.149 142.781C340.157 147.153 342.713 157.094 343.272 172.89C343.341 174.754 343.409 176.883 343.398 178.931C343.329 179.644 343.329 184.442 343.535 185.236C343.535 185.236 337.67 186.421 335.913 186.306L335.924 186.283Z"
        fill="url(#paint16_linear_133_230)"
      />
      <path
        d="M383.115 170.624C379.418 175.824 374.5 178.747 367.906 178.988C361.128 179.23 355.401 176.687 350.985 171.429C346.672 166.436 345.429 161.236 345.212 155.287C344.847 144.99 351.692 134.359 364.54 133.887C373.782 133.554 385.556 140.33 386.424 154.862C386.526 160.281 386.367 165.792 383.103 170.612L383.115 170.624ZM363.946 146.796C360.512 146.923 359.166 151.053 359.234 153.101C359.417 158.164 363.193 165.309 367.506 165.147C371.637 164.998 373.268 161.121 373.177 158.727C373.131 157.485 372.812 155.989 372.401 154.309C371.306 150.708 367.369 146.67 363.946 146.796V146.796Z"
        fill="url(#paint17_linear_133_230)"
      />
      <path
        d="M446.792 155.356C445.868 159.027 442.867 168.737 439.935 178.079L432.097 180.668L426.517 169.945C424.532 166.022 421.474 161.776 419.979 159.613L419.797 159.441C416.545 164.538 411.297 175.468 408.307 180.91L401.165 180.726L395.072 158.118C392.973 150.996 390.6 146.371 387.097 139.306C388.808 138.006 391.227 136.407 392.505 135.464C394.057 134.612 397.023 133.703 401.462 132.208C403.207 139.076 403.629 141.285 407.589 155.794L407.668 155.609L417.515 135.268C419.614 134.923 421.988 134.578 423.482 134.693L433.648 155.563C434.812 151.076 435.725 144.564 437.208 136.695C437.573 134.555 437.653 131.701 437.596 130.286C442.673 129.481 448.549 131.138 451.687 132.53L451.037 136.637C450.295 140.48 449.029 146.923 446.769 155.356H446.792Z"
        fill="url(#paint18_linear_133_230)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_133_230"
        x="-0.000671387"
        y="0.0256348"
        width="486.414"
        height="202.358"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_133_230" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_133_230" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_133_230"
        x1="212.015"
        y1="577.878"
        x2="40.1729"
        y2="91.6608"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_133_230"
        x1="248.834"
        y1="564.865"
        x2="76.992"
        y2="78.6483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_133_230"
        x1="290.365"
        y1="550.185"
        x2="118.535"
        y2="63.9789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_133_230"
        x1="334.498"
        y1="534.595"
        x2="162.668"
        y2="48.3778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_133_230"
        x1="394.388"
        y1="513.425"
        x2="222.545"
        y2="27.2081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_133_230"
        x1="433.203"
        y1="499.711"
        x2="261.361"
        y2="13.4937"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_133_230"
        x1="487.331"
        y1="480.577"
        x2="315.489"
        y2="-5.63957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_133_230"
        x1="524.036"
        y1="467.611"
        x2="352.194"
        y2="-18.6063"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_133_230"
        x1="552.343"
        y1="457.601"
        x2="380.513"
        y2="-28.6159"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_133_230"
        x1="592.699"
        y1="443.335"
        x2="420.857"
        y2="-42.871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_133_230"
        x1="206.276"
        y1="579.903"
        x2="34.4454"
        y2="93.6972"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_133_230"
        x1="242.022"
        y1="567.27"
        x2="70.1804"
        y2="81.0645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_133_230"
        x1="277.518"
        y1="554.729"
        x2="105.676"
        y2="68.5122"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_133_230"
        x1="313.812"
        y1="541.901"
        x2="141.97"
        y2="55.6837"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_133_230"
        x1="352.32"
        y1="528.29"
        x2="180.49"
        y2="42.0728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_133_230"
        x1="396.841"
        y1="512.562"
        x2="224.999"
        y2="26.3453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_133_230"
        x1="438.258"
        y1="497.916"
        x2="266.416"
        y2="11.7103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_133_230"
        x1="479.618"
        y1="483.304"
        x2="307.787"
        y2="-2.91296"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_133_230"
        x1="533.38"
        y1="464.297"
        x2="361.55"
        y2="-21.9084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009FE3" />
        <stop offset="0.1" stopColor="#0FA2DE" />
        <stop offset="0.28" stopColor="#38ABD2" />
        <stop offset="0.54" stopColor="#79BABF" />
        <stop offset="0.85" stopColor="#D3CEA5" />
        <stop offset="1" stopColor="#FFD899" />
      </linearGradient>
    </defs>
  </svg>
);

export default YellowText;
