import React from 'react';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import LogoSvg from './LogoSvg';

const useStyles = makeStyles(theme => ({
  root: showCta => ({
    display: showCta ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    zIndex: '1000',
    top: '50px',
    width: '100vw',
    gap: '10px',
    padding: '15px',
    background: '#653CFF',
    color: 'white',
    fontWeight: '600',
    cursor: 'pointer',
    boxShadow: '0px 0px 20px 0px #000000CC',
    WebkitTransform: showCta ? 'translateY(20%)' : 'translateY(-150%)',
    MsTransform: showCta ? 'translateY(20%)' : 'translateY(-150%)',
    transform: showCta ? 'translateY(20%)' : 'translateY(-150%)',
    '& span': {
      fontWeight: '800'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '200px',
      flexDirection: 'column',
      borderRadius: '15px',
      position: 'fixed',
      maxWidth: '100vw',
      WebkitTransition: 'all .3s ease-in-out',
      MozTransition: 'all .3s ease-in-out',
      OTransition: 'all .3s ease-in-out',
      transition: 'all .3s ease-in-out',
      top: '70px',
      bottom: 'unset',
      right: '45px',
      zIndex: 3
    }
  }),
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  name: {
    fontSize: '19px',
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    },
    '@media only screen and  (max-width: 340px)': {
      fontSize: '14px'
    }
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    },
    '@media only screen and  (max-width: 340px)': {
      fontSize: '14px'
    }
  },
  subtitle: {
    fontSize: '16px',
    textAlign: 'center',
    '@media only screen and  (max-width: 340px)': {
      fontSize: '14px'
    }
  }
}));

const TishaBavProducerCircleCta = ({ showCta, isEventLive }) => {
  const classes = useStyles(showCta);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const GoToSection = Scroll.Link;

  return (
    <GoToSection to="Backstage" smooth duration={500} offset={isXs ? -186 : -76}>
      <div className={classes.root}>
        <Typography variant="body1" className={classes.heading}>
          Scroll Down for our...
        </Typography>
        <div className={classes.logoSection}>
          <LogoSvg />
          <Typography className={classes.name} gutterBottom>
            <span>Patrons</span> Circle Event
          </Typography>
          {isEventLive && !isXs && (
            <Typography className={classes.subtitle}>
              <strong>Join us later today</strong>
            </Typography>
          )}
        </div>
      </div>
    </GoToSection>
  );
};

export default TishaBavProducerCircleCta;

TishaBavProducerCircleCta.propTypes = {
  showCta: PropTypes.bool.isRequired,
  isEventLive: PropTypes.bool.isRequired
};
