import React from 'react';
import { Element } from 'react-scroll';
import PropTypes from 'prop-types';
import ScrollTrigger from 'react-scroll-trigger';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useUserContext } from '~src/context/UserContext/UserSessionContext';
import Container from '~src/components/Container';
import Link from '~src/components/Link';
import { DateSection } from '../shared';
import LongImage from './images/long-image.png';
import ShortImage from './images/short-image.png';
import EndEventImage from './images/end-event.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#EBEBFF',
    padding: '30px 20px',
    borderRadius: '0',
    marginBottom: '32px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '20px',
      padding: '60px 80px'
    }
  },
  heading: {
    fontWeight: '600',
    fontSize: '74px',
    fontFamily: 'Roboto Slab',
    '& span': {
      borderRadius: '5px',
      padding: '0 10px',
      background:
        'linear-gradient(271.88deg, #F7941D 8.38%, #F7A124 24.2%, #FAC035 65.82%, #FBCC3C 89.13%)'
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '35px'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '31px'
    },
    '@media only screen and  (max-width: 330px)': {
      fontSize: '28px'
    }
  },
  subheading: {
    fontSize: '40px',
    fontWeight: '700',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      textAlign: 'center'
    },
    '@media only screen and  (max-width: 330px)': {
      fontSize: '23px'
    }
  },
  paragraph: {
    fontSize: '24px',
    marginBottom: '15px',
    textAlign: 'left',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      textAlign: 'center'
    }
  },
  ctaText: {
    fontSize: '40px',
    fontWeight: '700',
    textAlign: 'center',
    lineHeight: '47px',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '20px'
    }
  },
  longImage: {
    maxWidth: '100%',
    marginBottom: '20px'
  },
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto'
  },
  shortImage: {
    maxHeight: '600px',
    marginTop: '40px',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '750px'
    }
  },
  button: {
    backgroundColor: '#653CFF',
    color: '#fff',
    fontSize: '26px',
    lineHeight: '31px',
    fontWeight: '600',
    padding: '24px 52px',
    boxShadow: 'unset',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#571CFF'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '22px',
      lineHeight: '23px',
      padding: '24px 36px'
    }
  },
  ctaContainer: {
    padding: '35px 60px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '20px 15px'
    }
  }
}));

const getButtonProps = (isProducer, isEventLive, isEventEnded) => {
  if (!isProducer && isEventEnded) {
    return {
      text: 'WATCH NOW',
      to: '/playlist/why-does-god-allow-suffering'
    };
  }
  if (!isProducer) {
    return { text: 'JOIN THE CIRCLE', to: '/express-checkout?planId=4&period=year' };
  }

  if (isEventLive) {
    return {
      text: 'JOIN NOW',
      to: 'https://us02web.zoom.us/j/82883876543?pwd=RF_ok7yjX2P3wzRrfqEKUFgSTkhtne.1'
    };
  }

  return {
    text: 'REGISTER NOW',
    to: 'https://us02web.zoom.us/meeting/register/tZYlf-qgqT0vHtdX1K0droy_Q0g5ax98xxJB'
  };
};

const BackstagePass = ({ isEventEnded, isEventLive, setShowCta }) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const { isProducer } = session;

  const { text, to } = getButtonProps(isProducer, isEventLive, isEventEnded);
  const handleOnEnter = () => setShowCta(false);

  return (
    <Element name="Backstage">
      <Container center className={classes.root}>
        <Typography component="h2" className={classes.heading}>
          BACKSTAGE <span>PASS</span>
        </Typography>

        {!isEventEnded ? (
          <>
            <Typography component="h4" align="center" className={classes.subheading}>
              An Exclusive Patrons Circle Event
            </Typography>
            <img src={LongImage} alt="" className={classes.longImage} />
            <ScrollTrigger onEnter={handleOnEnter}>
              <Typography
                component="p"
                className={classes.paragraph}
                style={{ textAlign: 'center' }}
              >
                This Tisha B’Av afternoon, Rabbi Fohrman and Imu Shalev will be holding an exclusive
                event for Aleph Beta’s Patrons Circle. This free-wheeling discussion will go behind
                the scenes of the latest Tisha B’Av video, offering a taste of the extensive
                research that didn’t make the final cut and venturing into uncharted territory with
                the help of audience participation.{' '}
              </Typography>
            </ScrollTrigger>

            <Grid container direction="row-reverse" alignItems="center" justifyContent="center">
              <Grid item xs={12} lg={7}>
                <img src={ShortImage} alt="" className={classes.shortImage} />
              </Grid>
              <Grid item xs={12} lg={5}>
                <Typography
                  component="h4"
                  className={classes.subheading}
                  style={{ lineHeight: '35px', marginBottom: '25px' }}
                >
                  What’s Patrons Circle?{' '}
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  Aleph Beta’s Backbone. Through an annual commitment of $1800, Patrons Circle
                  members support the Torah they love at the highest level so that thousands of
                  people across the globe have the chance to fall in love with that Torah too.
                </Typography>
                <Typography component="p" className={classes.paragraph}>
                  Exclusive events, like Backstage Pass, are just one of the ways we get to say
                  thank you to this inner circle that makes everything we do possible.
                </Typography>
              </Grid>
            </Grid>

            <Box bgcolor="#fff" borderRadius="15px" my="30px" className={classes.ctaContainer}>
              <Typography component="p" className={classes.ctaText}>
                Make an impact. Join the inner circle.
              </Typography>
              <Typography component="p" className={classes.ctaText}>
                Then join us Tisha B’Av afternoon for Backstage Pass.
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <img src={EndEventImage} alt="" className={classes.media} />
            <Typography
              className={classes.paragraph}
              style={{ marginTop: '20px', color: '#040057' }}
            >
              This event is now over, but there’s a whole library of meaningful Tisha B’Av videos
              waiting for you
            </Typography>
          </>
        )}
        {isEventLive && (
          <Typography
            component="p"
            className={classes.paragraph}
            style={{
              borderRadius: '5px',
              padding: '0px 5px',
              fontSize: '22px',
              marginBottom: '8px'
            }}
            gutterBottom
          >
            <strong>This event is currently in progress:</strong>
          </Typography>
        )}
        <Button
          component={Link}
          to={to}
          target="_blank"
          variant="contained"
          size="large"
          className={classes.button}
        >
          {text}
        </Button>
        {!isEventEnded && !isProducer && (
          <>
            <Typography className={classes.paragraph} style={{ marginTop: '20px' }}>
              Not ready to sign up? Learn more about Patrons Circle{' '}
              <Link to="/patrons-circle" underline="always" style={{ color: '#0777E5' }}>
                <span className={classes.paragraphLink}>here</span>
              </Link>
              .
            </Typography>
          </>
        )}
        <DateSection variant="dark" />
      </Container>
    </Element>
  );
};

export default BackstagePass;

BackstagePass.propTypes = {
  isEventEnded: PropTypes.bool.isRequired,
  isEventLive: PropTypes.bool.isRequired,
  setShowCta: PropTypes.func.isRequired
};
