import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SlideNavButton from '~components/buttons/SlideNavButton/SlideNavButton';

const useStyles = makeStyles({
  navButtonContainer: {
    display: 'flex',
    gap: 14,
    marginRight: '15px'
  }
});

const NavigationButtons = ({
  sliderRef,
  selectedCarousel,
  selectedSlideIndex,
  cardsToShow,
  variant
}) => {
  const classes = useStyles();

  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);

  useEffect(() => {
    const cards = selectedCarousel ? selectedCarousel.cards : [];
    const isLast = selectedSlideIndex + cardsToShow === cards.length;
    const isFirst = selectedSlideIndex === 0;
    setDisableNext(isLast);
    setDisablePrev(isFirst);
  }, [selectedSlideIndex, selectedCarousel, cardsToShow]);

  const navigateNext = () => {
    sliderRef.current.slickNext();
  };

  const navigatePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className={classes.navButtonContainer}>
      <SlideNavButton
        onClick={navigatePrev}
        direction="prev"
        disabled={disablePrev}
        variant={variant}
      />
      <SlideNavButton
        onClick={navigateNext}
        direction="next"
        disabled={disableNext}
        variant={variant}
      />
    </div>
  );
};

NavigationButtons.propTypes = {
  sliderRef: PropTypes.shape({
    current: PropTypes.shape({
      slickNext: PropTypes.func,
      slickPrev: PropTypes.func
    })
  }).isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
  cardsToShow: PropTypes.number.isRequired,
  selectedCarousel: PropTypes.shape({
    category: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        label: PropTypes.string,
        link: PropTypes.string,
        image: PropTypes.string,
        duration: PropTypes.string
      })
    )
  }),
  variant: PropTypes.oneOf(['light', 'dark'])
};

NavigationButtons.defaultProps = {
  selectedCarousel: null,
  variant: 'light'
};

export default NavigationButtons;
